import { Skeleton, Stack } from '@mui/material'
import _ from 'lodash'
import React from 'react'

const FetchListLoad = ({ load, list }: { load: any, list: any }) => {
  return (
    <section className='fetchListLoad'>
      {load
        ? <div className='emptyLoad'>
          <Stack  style={{ width: "100%" }}>
            {new Array(3)?.fill("a").map((i, n) =>
              <Skeleton key={n} variant="text" sx={{ fontSize: '3rem' , height:"150px" }} />
            )}
          </Stack>
        </div>
        : _.isEmpty(list)
        && <div className='emptyLoad'>
          <img crossOrigin='anonymous' src="/svg/cardDown.svg" alt="" />
          <p>موردی یافت نشد</p>
        </div>
      }
    </section>

  )
}

export default FetchListLoad