import Tabs from 'components/Helper/Tabs';
import Top from 'components/Layouts/Top';
import _ from 'lodash';
import HistOrder from './HistOrder';
import HistDFiat from './HistDFiat';
import HistWFiat from './HistWFiat';
import HistDCoin from './HistDCoin';
import HistWCoin from './HistWCoin';
import { useState } from 'react';

const tabTitles = {
  order: 'سفارشات',
  paymentDeposit: 'واریز تومان',
  paymentWithdraw: 'برداشت تومان',
  deposit: 'واریز رمزارز',
  withdraw: 'برداشت رمزارز',
};

const History = ({ defaultView = "سفارشات" }: any) => {
  const [tab, setTab] = useState(defaultView);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'تاریخچه تراکنش ها'} />
        <div className='container inData historyStyleNew'>
          <div className='tabs tabsTop'>
            <Tabs titles={tabTitles} selected={tab} setSelected={setTab} />
          </div>
          {tabTitles.order === tab ? <HistOrder />
            : tabTitles.paymentDeposit === tab ? <HistDFiat />
              : tabTitles.paymentWithdraw === tab ? <HistWFiat />
                : tabTitles.deposit === tab ? <HistDCoin />
                  : tabTitles.withdraw === tab ? <HistWCoin />
                    : ""}
        </div>
      </div>
    </div>
  );
};

export default History;
