import Tabs from 'components/Helper/Tabs';
import Top from 'components/Layouts/Top';
import { useEffect, useState } from 'react';
import Code from './Code';
import Crypto from './Crypto';
import Fiat from './Fiat';
import moment from 'jalali-moment';
import { statusColor } from 'utils/color';
import { statusPayment } from 'utils/translate';
import { zeroFormat } from 'utils/numeral';
import { centerDialog } from 'services/reducer/dialog';
import Detail from '../Detail';
import useGlobal from 'hooks/useGlobal';
import { useDispatch } from 'react-redux';
import config from 'config';
import gather from 'utils/gather';
import LoadingList from 'components/LoadingList';
import History from '../History';
import { fullDrawer } from 'services/reducer/drawer';
import Card from './Card';

const tabTitles = {
  toman: 'واریز شتابی',
  card: 'کارت به کارت',
  more: 'واریز شناسه دار',
  crypto: 'ارز دیجیتال',
};

const Deposit = ({ symbol }: { symbol?: string }) => {
  const global = useGlobal();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(tabTitles.toman);
  const [listHistory, setListHistory] = useState([]);
  const [loadingList, setLoadingList] = useState(true);
  const [from, _setFrom] = useState(Math.floor(moment().subtract(60, 'd').valueOf()));
  const [to, _setTo] = useState(Math.floor(moment().add(10, 'm').valueOf()));

  const getData = async (address: string, typeQuery: any, key: any) => {
    const result = await gather(`${config.data.api}/v1/${address}?fromDate=${from}&toDate=${to}${typeQuery}`, true).get();
    if (result.code === 200) {
      return result.data.map((e: any) =>
        Object.assign(
          { tab: key },
          e,
        ),
      );
    }
    return [];
  };

  const init = async () => {
    setLoadingList(true);

    setListHistory([]);

    const result = await Promise.all([getData('deposit', "", "ارز دیجیتال"),
    getData('payment', "&type=cashCode", "واریز شناسه دار"),
    getData('payment', "&type=card", "کارت به کارت"),
    getData('payment', "", "واریز شتابی")])
      .then((results) => {
        return [].concat.apply([], [results[0], results[1], results[2], results[3]]);
      });

    setListHistory(result.sort((b: any, a: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));

    setLoadingList(false);
  };

  useEffect(() => {
    init();
  }, [global.orderReload]);


  useEffect(() => {
    if (symbol) {
      if (symbol !== 'tmn') {
        setTab(tabTitles.crypto);
      }
    }
  }, []);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'واریز'} />
        <div className='container inData p_lf'>
          <div className='tabs tabsTop'>
            <Tabs titles={tabTitles} selected={tab} setSelected={setTab} isNew={true} />
          </div>
          {tab === tabTitles.toman ? <Fiat /> : tab === tabTitles.more ? <Code />
            : tab === tabTitles.crypto ? <Crypto symbol={symbol} /> : <Card />}
        </div>
        {(tab === tabTitles.more || tab==tabTitles.card) && (
          <div className='container codeDanger'>
            <p>برای واریز لطفا از سامانه پل استفاده نکنید. در صورت واریز مبلغ شارژ نخواهد شد.</p>
          </div>
        )}
        {tab === tabTitles.more && (
          <div className='container codeAlert'>
            <p>کاربر گرامی، به دستور پلیس فتا محترم ؛ بعد از استفاده از واریز شناسه‌دار، امکان برداشت ارز دیجیتال تا ۳۶ ساعت را ندارید.</p>
          </div>
        )}
        <div className='container inData'>
          <h4 className='notesTitle'>نکات مهم</h4>
          <div className='noteList'>
            {tab === tabTitles.toman ? (
              <>
                <span>بعداز پرداخت از طریق درگاه الکترونیک شاپرک ، در صورت عدم شارژ کیف پول تومان ، لطفا صفحه خود را بعداز 2 دقیقه refresh کنید و مجدد بررسی کنید.</span>
                <span>برای واریز ریال یکی از کارت های تایید شده را انتخاب کنید و اگر کارت تایید شده ندارید در قسمت کارت های بانکی در بخش حساب بانکی اضافه کنید.</span>
                <span>دقت کنید ، کارت بانکی که قصد ثبت در پروفایلتان دارید باید با کد ملی شما مطابقت داشته باشد.</span>
                <span>با توجه به ابلاغیه بانک مرکزی در مورد نظام کارمزد شاپرکی و همچنین کارمزد سرویس های پرداخت یاری، واریز ریالی از درگاه پرداخت به حساب کاربری در اکسنوین مشمول کارمزد خواهد بود. </span>

              </>
            ) : tab === tabTitles.more ? (
              <>
                <span><i className='colorRed'>بدون محدودیت</i> در مقدار واریزی ، میتوانید به حساب های مذکور <i className='colorRed'>( پایا/ساتنا )</i> از طریق نت بانک ، همراه بانک و شعبه واریز کنید.</span>
                <span>برای استفاده از سرویس واریز شناسه دار سطح کاربری 2 به بالا نیاز است.</span>
                <span>با انتخاب دکمه آبی رنگ " درخواست صدور شناسه واریز " سیستم به صورت خودکار ، شناسه واریز / شناسه پرداخت شما را ایجاد کرده و میتوانید بلافاصله از این سرویس استفاده کنید.
                </span>
                <span>در ادامه شناسه واریز یا همان شناسه پرداخت برای شما ایجاد می شود و لیست حساب ها قابل مشاهده می باشد.</span>
                <span>در آخر هنگام واریز وجه از روش های پایا، ساتنا و حساب به حساب حتما در بخش شناسه‌ی واریز، شناسه‌ی دریافتی خود را به دقت وارد کنید ، در غیر اینصورت تراکنش شما عودت خواهد شد.</span>
                <span> توجه داشته باشید که پس از وصول مبلغ به حساب های اکسنوین ، کیف پول تومانی شما شارژ خواهد شد </span>
                <span>کارمزد 0.02% سرویس شناسه‌دار : به طور مثال اگر شما مبلغ 100 میلیون تومان واریز کنید ، از مبلغ تراکنش 20 هزار تومان کسر خواهد شد.</span>

              </>
            ) : tab === tabTitles.crypto ? (
              <>
                <span>در این صفحه آدرس واریزی کیف پول (شامل آدرس و یا در صورت لزوم تگ/ ممو و شبکه واریزی دلخواه خود را انتخاب و کپی کنید.</span>
                <span>آدرس کیف پول و در صورت لزوم آدرس تگ/ممنو را از صفحه کپی کنید و هنگام انتقال ارز دیجیتال در کیف پول ( مبدا ) خود ، وارد کنید.</span>
                <span>سپس منتظر بمانید ، بعداز تایید (confirm) شبکه ، به صورت خودکار مقدار رمزارز انتقالی به کیف پول شما واریز خواهد شد.</span>
                <span>در صورت هرگونه مشکل یا مغایرت ، Hash تراکنش یا همان Transaction ID به همراه اسکرین شات انتقال رمزارز ، از طریق تیکت به پشتیبانی ارسال کنید.</span>

              </>
            )
              : (
                <>
                  <span>لطفا دقت کنید کارت بانکی مبدا بنام شما و حتما در پروفایل کاربری شما ثبت و تاییده شده باشد.</span>
                  <span>سقف واریزی روزانه از هر کارت<i className='colorRed'> 10,000,000 تومان </i> است. به طور مثال : از 5 کارت مختلف میتوانید 5 بار 10 میلیون واریز کنید.</span>
                  <span>پس از انجام کارت به کارت ، تا <i className='colorRed'> 5 دقیقه </i> منتظر بمانید تا سیستم به صورت خودکار واریزی شما را شناسایی و مبلغ به کیف پول شما اضافه کند.</span>
                  <span>سعی کنید از طریق <i className='colorRed'>اپلیکیشن های همراه بانک یا عابر بانک ATM</i> ، کارت به کارت کنید. متاسفانه از اپ های ماننده آپ ، سکه ، همراه کارت و ... محدودیت های مختلفی دارند.</span>
                  <span>در حال حاضر کارمزد سرویس کارت به کارت  رایگان است.</span>
                </>
              )}
          </div>
          <div className='spacer'></div>
        </div>
        <div className='container inData justStart '>
          <div className='topTilteBox'>
            <p className='t1'>تراکنش ها</p>
            <div className='refreshApi' onClick={init}>
              بروزرسانی
            </div>
          </div>
          <div className='historyList'>
            {loadingList ? <LoadingList /> :
              Array.from(listHistory).filter((e: any) => e.tab === tab).length > 0
                ? Array.from(listHistory).filter((e: any) => e.tab === tab)?.slice(0, 3)
                  ?.map((i: any, n: any) =>
                    <div key={n} className='itemWithdraw'
                      onClick={() => dispatch(centerDialog(<Detail data={i} type={i.tab == tabTitles.crypto ? "deposit" : "payment"} />))}
                    >
                      <section className='right'>
                        {i?.tab === tabTitles.crypto
                          ? <img className='icon' crossOrigin="anonymous" src={global.coins[i?.coin?.symbol]?.icon} alt="" />
                          : <img className='icon' crossOrigin='anonymous' src='/images/tmn.png' />}
                        <section className='txtBox'>
                          <p className='t1'>
                            {i.tab === tabTitles.toman
                              ? "واریز تومان"
                              : i.tab === tabTitles.crypto ? "واریز " + i?.coin?.symbol?.toUpperCase()
                                : i.tab}
                          </p>
                          <p className='t2'>
                            {moment(i.createdAt)
                              .locale('fa')
                              .format('dddd D MMMM YYYY | HH:mm')}
                          </p>
                        </section>
                      </section>
                      <section className='left'>
                        <p className='t1'>{i.tab === tabTitles.crypto
                          ? i?.coin?.symbol?.toUpperCase()
                          : "تومان"} {zeroFormat(i?.amount)} </p>
                        <span className={`boxStatus bg${statusColor(i?.status)}`}>{statusPayment(i?.status)}</span>
                      </section>
                    </div>)
                : <div className='noItem alignCenter'>موردی یافت نشد</div>}
          </div>
          {Array.from(listHistory).filter((e: any) => e.tab === (tab == tabTitles.crypto ? tabTitles.crypto : tabTitles.toman)).length > 0
            && <div className='btnShowMore' onClick={() => dispatch(fullDrawer(<History defaultView={tab === tabTitles.crypto ? "واریز رمزارز" : "واریز تومان"} />))}>
              <span>مشاهده بیشتر</span>
            </div>}
        </div>
      </div>
    </div>
  );
};

export default Deposit;
