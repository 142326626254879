import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { fullDrawer } from 'services/reducer/drawer';
import bank from 'utils/bank';
import _ from 'lodash';
import SelectBox from 'components/Helper/SelectBox';
import NewCard from '../NewCard';
import { ICard } from 'interfaces/global';

const Card = () => {
  const dispatch = useDispatch<any>();
  const user = useUser();
  const [card, setCard] = useState<ICard | null>(null);

  const copy = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch(successAlert('کپی شد'));
    } catch (error) { }
  };


  return (
    <>
      <div className='step stepDeposit'>
        <i>۱</i>
        <h3>انتخاب کارت مبدا</h3>
        <section className='addCard' onClick={() => dispatch(fullDrawer(<NewCard />))}>
          افزودن کارت
        </section>
      </div>
      <SelectBox
        data={user.cards}
        search={false}
        symbol={""}
        value={
          card ? (
            <>
              <img src={bank.detect(card.number!)?.logo || '/svg/cardDown.svg'} />
              <h3>{card.bank}</h3>
              <h2>{card.number!.match(new RegExp('.{1,4}', 'g'))?.join('-')}</h2>
            </>
          ) : (
            <>شماره کارت خود را انتخاب کنید</>
          )
        }
        template='card'
        setSelected={setCard}
      />
      <p className='cashCardAlert'> شماره کارت باید به نام خودتان باشد و در اکسنوین ثبت شده باشد</p>
      <div className='step stepDeposit'>
        <i>۲</i>
        <h3>شماره کارت مقصد</h3>
      </div>
      <div className='listCashCode'>
        <section>
          <div onClick={() => copy("6037697090121291")}>
            <span>شماره کارت مقصد</span>
            <i></i>
            <span>6037-6970-9012-1291</span>
            <img crossOrigin='anonymous' src={bank.logo("saderat")!} alt='' />
          </div>
          <div>
            <span>نام صاحب کارت</span>
            <span>شرکت داد و ستد فناوران پارس نوین</span>
          </div>
          <div>
            <span>نام بانک</span>
            <span>بانک صادرات</span>
          </div>
        </section>
      </div>
      <div className='spacer'></div>
    </>
  );
};

export default Card;
