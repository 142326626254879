import { Pagination } from '@mui/material';
import Tabs from 'components/Helper/Tabs';
import Top from 'components/Layouts/Top';
import config from 'config';
import useBalance from 'hooks/useBalance';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { zeroFormat } from 'utils/numeral';

const tabTitles = {
  new: 'ساخت کارت هدیه',
  use: 'استفاده از کارت هدیه',
  list: 'لیست هدیه ها',
};

const Gift = () => {
  const [tab, setTab] = useState(tabTitles.new);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const global = useGlobal();
  const balance = useBalance();
  const [code, setCode] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [amount, setAmount] = useState<any>({});
  const [data, setData] = useState<any>({});
  const [pageCount, setPageCount] = useState(1);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [reloadApi, setReloadApi] = useState("");
  const [valueTime, setValueTime]: any = useState("");

  const needRender = () => {
    if (Object.values(data).length === 0) {
      return 'انتخاب ارز';
    }

    const rows: JSX.Element[] = [];
    Object.entries(data).forEach(([k, v]) => {
      rows.push(
        <i
          onClick={() =>
            setData((prevState: any) => {
              const newData = { ...prevState };
              delete newData[k];
              return newData;
            })
          }
        >
          {zeroFormat(Number(v)) + ' ' + k}
        </i>,
      );
    });

    return rows;
  };

  const balanceRender = () => {
    const rows: JSX.Element[] = [];

    Object.values(balance)
      .sort((a, b) => {
        return b.value.usdt - a.value.usdt;
      })
      .filter((e) => e.value.usdt >= 1)
      .forEach((e, i) => {
        rows.push(
          <div key={i} className='addGift'>
            <span className='inlineFlex' style={{ gap: '10px' }}>
              <img crossOrigin='anonymous' src={e.icon} />
              <input
                type='number'
                value={amount[e.symbol] || ''}
                placeholder={'مقدار ' + e.nameFa}
                onChange={(v) =>
                  setAmount((prevState: any) => ({
                    ...prevState,
                    [e.symbol]: v.target.value,
                  }))
                }
              />
            </span>
            <span 
            onClick={() => {
              setAmount((prevState: any) => ({
                ...prevState,
                [e.symbol]: e.balance+"",
              }))
            }}
            >موجودی: {zeroFormat(e.balance)}</span>
            <p
              onClick={() => {
                amount[e.symbol] &&
                  setData((prevState: any) => ({
                    ...prevState,
                    [e.symbol]: amount[e.symbol],
                  }));

                setAmount((prevState: any) => ({
                  ...prevState,
                  [e.symbol]: '',
                }));
              }}
            ></p>
          </div>,
        );
      });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return rows;
  };

  const daysRender = (from: number, to: number) => {
    const rows: JSX.Element[] = [];
    for (let i = from; i <= to; i++) {
      rows.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
    return rows;
  };

  const saveAction = async () => {

    if (title?.trim().length == 0) {
      dispatch(errorAlert("عنوان را وارد کنبد"));
      return
    }
    if (Object.keys(data)?.length <= 0) {
      dispatch(errorAlert("لطفا ارز هدیه را وارد کنید"));
      return;
    }

    if (mobile.trim().length === 0) {
      dispatch(errorAlert("موبایل دریافت کننده را وارد کنبد"));
      return
    }

    if (valueTime == "") {
      dispatch(errorAlert("تاریخ انقضا را وارد کنبد"));
      return
    }

    dispatch(showLoading());

    const final: any = [];
    Object.entries(data).forEach(([k, v]) => {
      final.push({
        [k === 'tmn' ? 'fiat' : 'coin']: k === 'tmn' ? 'tmn' : global.coins[k]._id,
        amount: v,
      });
    });

    const result = await gather(`${config.data.api}/v1/gift`, true).post({
      title: title,
      text: text,
      mobile: mobile,
      expire: valueTime,
      // expire: moment.from(year + '-' + month + '-' + day, 'fa', 'YYYY-MM-DD').valueOf(),
      data: final,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert('ثبت با موفقیت انجام شد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  const saveCode = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/gift/use`, true).post({
      code: code,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert('ثبت با موفقیت انجام شد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  const handleActive = async (item: any) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/gift/${item?._id}`, true).put({
      "isActive": !item?.isActive,
    });

    if (result.code === 200) {
      dispatch(successAlert("وضعیت ثبت شد"));
      await init();
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  const copy = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch(successAlert('کپی شد'));
    } catch (error) {}
  };

  const listRender = () => {
    const rows: JSX.Element[] = [];

    list.forEach((e: any, i) => {
      const inner: JSX.Element[] = [];
      e.data.forEach((d: any) => {
        inner.push(
          <i>
            {d.fiat || d.coin.symbol} <b>{d.amount}</b>
          </i>,
        );
      });

      rows.push(
        <div>
          <div className='topTitle'>
            <div className='txt'>
              <p className='t1'>{e?.title}</p>
              <p className='t2'>{e?.text}</p>
            </div>
            {/* <p className={`infoActive ${e.isActive ? "color_green" : "color_red"}`} onClick={() => handleActive(e)}>{e.isActive ? "فعال" : "غیرفعال"}</p> */}
          </div>

          {/* <h3>{e.title}</h3>
          <p className='colorGreen'>{e.text}</p> */}
          <section className='needRenderShow'>{inner}</section>
          <h4>
            موبایل <b>{e.mobile}</b>
          </h4>
          <h4 onClick={() => copy(e.code)}>
            کد هدیه
            <i className='f1'></i>
            <i className='copy'></i>
            <b>{e.code}</b>
          </h4>
          <h4>
            وضعیت <b className={`boxStatus colorWhite bg${e.receiver ? 'Green' : 'Yellow '}`}>{e.receiver ? 'مصرف شده' : 'مصرف نشده'}</b>
          </h4>
          <h4>
            تاریخ انقضا{' '}
            <b>
              {moment(e.expire)
                .locale('fa')
                .format('d MMM yy')}
            </b>
          </h4>
        </div>,
      );
    });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/gift`, true).get();
    if (result.code === 200) {
      setList(result.data);
      setPageCount(result?.data?.pages?.count ?? 1);
      setPageCurrent(result?.data?.pages?.current ?? 1);
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, [reloadApi]);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'کارت هدیه'} />
        <div className='container inData'>
          <div className='tabs tabsTop'>
            <Tabs titles={tabTitles} selected={tab} setSelected={setTab} />
          </div>
          {tab === tabTitles.new ? (
            <>
              <div className='step stepDeposit'>
                <i>۱</i>
                <h3>عنوان</h3>
              </div>
              <div className='mainInput'>
                <div>
                  <input type='text' className='rtl' onChange={(e) => setTitle(e.target.value)} />
                </div>
              </div>

              <div className='step stepDeposit'>
                <i>۲</i>
                <h3>انتخاب ارز</h3>
              </div>
              <div className='selectParent selectParentFix'>
                <div className='noInput needRender'>
                  {needRender()}
                  <span className='f1'></span>
                </div>
                <div className='downSelect'>
                  <div className='listOfCoin'>{balanceRender()}</div>
                </div>
              </div>

              <div className='step stepDeposit'>
                <i>۲</i>
                <h3>توضیحات</h3>
              </div>
              <div className='mainInput'>
                <div>
                  <input type='text' className='rtl' onChange={(e) => setText(e.target.value)} />
                </div>
              </div>

              <div className='step stepDeposit'>
                <i>۳</i>
                <h3>شماره موبایل دریافت کننده هدیه</h3>
              </div>
              <div className='mainInput'>
                <div>
                  <input type='text' className='rtl' onChange={(e) => setMobile(e.target.value)} />
                </div>
              </div>

              <div className='step stepDeposit'>
                <i>۴</i>
                <h3>تاریخ انقضای هدیه</h3>
              </div>
              <div className='mainInput '>
                <section className='datePickerSelect'>
                  <DatePicker
                    value={valueTime}
                    onChange={(e: any) => setValueTime(e?.unix * 1000)}
                    calendar={persian}
                    locale={persian_fa}
                    minDate={new DateObject({ calendar: persian })}
                    calendarPosition="bottom-right"
                    placeholder='تاریخ انقضا'
                  />
                </section>
              </div>

              <div className='spacer'></div>
              <div className='largeButton largeButtonGreen' onClick={() => saveAction()}>
                ثبت و تایید
              </div>
            </>
          ) : tab === tabTitles.use ? (
            <>
              <div className='step stepDeposit'>
                <i>۱</i>
                <h3>کد فعالسازی</h3>
              </div>
              <div className='mainInput'>
                <div>
                  <input type='text' className='rtl' onChange={(e) => setCode(e.target.value)} />
                </div>
              </div>

              <div className='spacer'></div>
              <div className='largeButton largeButtonGreen' onClick={() => saveCode()}>
                ثبت و تایید
              </div>
            </>
          ) : (
            <>
              <div className='alertList'>{listRender()}</div>
              <div className='paging'>
                <Pagination color="primary" page={pageCurrent} count={pageCount === 0 ? 1 : pageCount} onChange={(_e: any, v: any) => { setPageCurrent(v); setReloadApi(new Date().getTime().toString()) }} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Gift;
