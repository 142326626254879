import { Backdrop, CircularProgress } from '@mui/material';
import InstallPWA from 'components/Layouts/InstallPWA';
import Digital from 'components/Pages/Digital';
import Home from 'components/Pages/Home';
import Market from 'components/Pages/Market';
import Price from 'components/Pages/Price';
import Wallet from 'components/Pages/Wallet';
import useLoading from 'hooks/useLoading';
import { IDispatch } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { getCoins, getLevels, getSetting } from 'services/reducer/global';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getTickers } from 'services/reducer/ticker';
import { connect, socket } from 'services/socket';
import { checkAuth, getUserData, isUserLogin } from 'utils/user';
import AlertList from './AlertList';
import DialogList from './DialogList';
import DrawerList from './DrawerList';
import { getToken, onMessage } from 'firebase/messaging';
import config from 'config';
import gather from 'utils/gather';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { messaging } from '../../firebase/firebaseConfig';
import MessageNotif from 'components/MessageNotif';
import { toast, ToastContainer } from 'react-toastify';
import OfflineNotification from 'components/OfflineNotification';
import useUser from 'hooks/useUser';



const App = () => {
  const user = useUser();
  const dispatch = useDispatch<IDispatch>();
  const { isLoadingShow } = useLoading();
  const [isReady, setIsReady] = useState(false);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

  const fcmPermission = async () => {
    const permission = await Notification.requestPermission();
    try {
      if (permission === "granted") {
        getToken(messaging, { vapidKey: 'BOFi9Vqhl0gmqvElW6J5d4lm4NRLq5-cokiUi-xPXEXBUuZAlQirQsH_bWGOXbMLOAJiazGNw_p9YK15T3qKoPQ' })
          .then(async (currentToken) => {
            if (currentToken) {
              console.log(" token : ", currentToken);
              await sendFCM(currentToken)
            } else {
              console.log('No registration token available.');
            }
          }).catch((err: any) => {
            console.log('An error token.');
          });
      } else {
        console.log("You denied for the notification");
      }
    } catch (error) {
      console.log('An error2 token.');
    }
  }

  onMessage(messaging, (payload) => {
    toast.info(<MessageNotif notification={payload.notification} />);
    // dispatch(successAlert(payload?.notification?.title));
    // console.log(payload);
  });

  const init = async () => {
    dispatch(showLoading());

    await checkAuth();

    await Promise.all([dispatch(getCoins()), dispatch(getTickers()), dispatch(getSetting()), dispatch(getLevels())]);

    if (isUserLogin()) {
      fcmPermission();
      await getUserData(dispatch);
    }

    connect(dispatch);

    socket.on('connect', () => {
      setIsReady(true);
      dispatch(hideLoading());
    });
  };

  const sendFCM = async (tokenFCM: any) => {
    const result = await gather(`${config.data.api}/v1/user/fcm`, true).post({ 'token': tokenFCM });

    if (result.message === 200) {
      console.log("fcm با موفقیت ثبت شد");
    } else {
      console.log(dispatch(errorAlert(config.errors[result?.message] || config.errors[99999])));
    }
  }

  useEffect(() => {
    init();
  }, []);


  useEffect(() => {
    if (user.versionUpdate?.trim().length > 0) {
      const currentVersion = localStorage.getItem("appVerPwaEX");
      if (!currentVersion) return
      if (currentVersion !== user.versionUpdate) {
        setIsUpdateAvailable(true);
        setTimeout(() => {
          setIsUpdateAvailable(false);
        }, 30000);
      }
    }
  }, [user.versionUpdate]);
  const handleUpdate = () => {
    localStorage.removeItem("appVerPwaEX");
    window.location.reload();
  };

  const handleClose = () => {
    setIsUpdateAvailable(false);
  };
  return (
    <BrowserRouter basename='/'>
      <div className='app'>
        <OfflineNotification />
        <Backdrop sx={{ backgroundColor: '#ffffffe8', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }} open={isLoadingShow}>
          <CircularProgress />
        </Backdrop>
        <AlertList />
        <DrawerList />
        <DialogList />
        <InstallPWA />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        // theme={theme}
        // transition:Bounce
        />
        {isReady && (
          <Routes>
            {/* <Route path={"/"} element={<Home />} /> */}
            {/* <Route path={"*"} element={<Home />} /> */}
            {/* <Route path='*' element={<Navigate to={"/order/fast/:coinParam?"} />} /> */}

            <Route path='/' element={<Navigate to={'/order/fast'} />} />
            <Route path='*' element={<Navigate to={'/order/fast'} />} />
            <Route path='/order/fast/:coinParam?' element={<Home />} />
            <Route path='/order/market/:coinPairParam?' element={<Market />} />
            <Route path='/price' element={<Price />} />

            {isUserLogin() && <Route path='/finance/wallet' element={<Wallet />} />}
            {user?.profile?.isOld && <Route path='/order/currency' element={<Digital />} />}
          </Routes>
        )}
        {isUpdateAvailable &&
          <div id="updateDialog" className="update-dialog">
            <div className='txtBox'>
              <span>نسخه جدیدی از اپلیکیشن آماده است!</span>
              <p className='tVer'>v{user.versionUpdate}</p>
            </div>
            <div className='btns'>
              <button onClick={handleUpdate}>به‌روزرسانی</button>
              <button className="close-btn" onClick={handleClose}>×</button>
            </div>
          </div>
        }
      </div>
    </BrowserRouter>
  );
};

export default App;
