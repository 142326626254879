import { useDispatch } from 'react-redux';
import { hideDialog } from 'services/reducer/dialog';

const AlarmMemo = ({ symbol,depo }: { symbol: string,depo:boolean }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className='popup popupMemo'>
        هنگام {depo?"واریز به اکسنوین،":"برداشت"} در شبکه {symbol}  حتما تگ را ثبت کنید. در غیر این صورت انتقال دارایی‌تان انجام نمی‌شود و دارایتان از بین می‌رود.
        <i onClick={() => dispatch(hideDialog())}>تایید می کنم</i>
      </div>
    </>
  );
};

export default AlarmMemo;
