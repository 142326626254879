import PatchCode from 'components/Helper/PatchCode';
import config from 'config';
import useTimer from 'hooks/useTimer';
import { ICode } from 'interfaces/register';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import VerificationInput from 'react-verification-input';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { numbersToEn } from 'utils/tools';

const Code = ({ mobile, setStep,bodySend }: ICode) => {
  const [code, setCode] = useState<any>('');
  const dispatch = useDispatch();
  const timer = useTimer();

  const confirmAction = async (code1:any) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/auth/signup/confirm`).post({
      mobile: mobile.startsWith('0') ? mobile : '0' + mobile,
      code: numbersToEn(code1?.toString()),
    });

    if (result.code === 200) {
      Cookies.set('pwaAccessToken', result.data.accessToken, { expires: 30 * 24 * 60 * 60, path: '/' });
      Cookies.set('pwaRefreshToken', result.data.refreshToken, { expires: 30 * 24 * 60 * 60, path: '/' });
      window.location.reload();
    } else {
      setCode([]);
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
      dispatch(hideLoading());
    }
  };

  const reloadCode = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/auth/signup`).post(bodySend)

    if (result.message === 200) {
      timer.start();
      setCode([]);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  }

  useEffect(() => {
    timer.start();
  }, []);

  return (
    <>
      <h4 className='confirmTitle'>تاییدیه پیامکی  </h4>
      <div className='rules'>
        کد تایید برای شماره <i>{mobile}</i> پیامک شد
      </div>
      <div className='codeNumberBox'>
        <VerificationInput
          onComplete={(e) => confirmAction(e)}
          onChange={(e) => { Number(e) >= 0 && setCode(e) }}
          value={code}
          placeholder='-'
          inputProps={{ type: "tel" }}
          classNames={{
            container: "containerVerify",
            character: "characterVerify",
            characterFilled: "character--filled",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
        />
      </div>
      <div className='changeMobile'>
        <i>
          {!timer.isFinish ? (
            <>
              {timer.minute ? `${timer.minute} دقیقه و ` : ''}
              {timer.second} ثانیه
            </>
          ) : (
            <div
              className='active'
              onClick={reloadCode}
            >
              ارسال مجدد کد
            </div>
          )}
        </i>
        <p onClick={() => setStep(1)}>ویرایش شماره</p>
      </div>
    </>
  );
};

export default Code;
