import moment from 'jalali-moment';
import { useDispatch } from 'react-redux';
import { successAlert } from 'services/reducer/alert';
import { statusColor, typeColor } from 'utils/color';
import { zeroFormat, formatter } from 'utils/numeral';
import { dotted } from 'utils/tools';
import { payTranslate, sideTranslate, statusPayment } from 'utils/translate';

const Payment = ({ data }: any) => {
  const dispatch = useDispatch();

  const copy = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch(successAlert('کپی شد'));
    } catch (error) {}
  };

  return (
    <div className='voucherData'>
      <div className='listCashCode listCashCodeCustom'>
        <section>
          <div>
            <span>شناسه درخواست</span>
            <i className='copy' onClick={() => copy(data?.uniqueId)}></i>
            <span className='ltr'>{data?.uniqueId} </span>
          </div>
        </section>
        {data?.side == "cashout"
          ? <section>
            <div>
              <span>کد پیگیری بانکی</span>
              <i className='copy' onClick={() => data?.hash && copy(data?.hash)}></i>
              <span className='ltr'> {dotted(data?.hash ?? "---",8)} </span>
            </div>
          </section>
          : <section>
            <div>
              <span>کد پیگیری درگاه</span>
              <i className='copy' onClick={() => data?.reference && copy(data?.reference)}></i>
              <span className='ltr'>{data?.reference ?? "---"} </span>
            </div>
          </section>}
        <section>
          <div>
            <span>وضعیت</span>
            <span className={`boxStatus bg${statusColor(data.status)}`}>{statusPayment(data.status)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>تاریخ</span>
            <span className='ltr'>{moment(data.createdAt).locale('fa').format('YYYY-MM-DD , HH:mm:ss')}</span>
          </div>
        </section>
        <section>
          <div>
            <span>نوع</span>
            <span className={`color${typeColor(data.side)}`}>{sideTranslate(data.side)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>روش پرداخت</span>
            <span>{payTranslate(data.type)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>ارز</span>
            <span className='currencyDetail'>
              Toman (TMN)
              <img crossOrigin='anonymous' src='/images/tmn.png' />
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>مقدار</span>
            <span className='ltr'>{zeroFormat(data.amount)} TMN</span>
          </div>
        </section>
        <section>
          <div>
            <span>کارمزد</span>
            <span className='ltr'>{zeroFormat(data.fee)} TMN</span>
          </div>
        </section>
        <section>
          <div>
            <span>مبلغ نهایی</span>
            <span className='ltr'>{zeroFormat(data.final)} TMN</span>
          </div>
        </section>
        <section>
          <div>
            <span>{data?.side == "cashout" ? "شماره شبا" : "شماره کارت"}</span>
            <span className='ltr'>{data?.side == "cashout" ? data?.card?.iban : data?.card?.number?.match(new RegExp('.{1,4}', 'g')).join("-")}</span>
          </div>
        </section>

      </div>
    </div>
  );
};

export default Payment;
