import { IForm } from 'interfaces/googleAuth';

const Download = ({ setStep }: IForm) => {
  return (
    <>
      <div className='emailLogo gLogo'></div>
      <div className='rules rulesCenter'>برای فعال‌سازی کد دوعاملی اپلیکیشن Google Authenticator را دانلود و نصب کنید</div>
      <div className='apps'>
        <a href='https://cafebazaar.ir/app/com.google.android.apps.authenticator2' target='_blank'></a>
        <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US' target='_blank'></a>
        <a href='https://apps.apple.com/us/app/google-authenticator/id388497605' target='_blank'></a>
      </div>
      <div className='largeButton' onClick={() => setStep(2)}>
        تایید و ادامه
      </div>
    </>
  );
};

export default Download;
