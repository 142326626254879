import { useDispatch } from 'react-redux';
import { hideDialog } from 'services/reducer/dialog';
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { errorAlert } from 'services/reducer/alert';
import VerificationInput from 'react-verification-input';
import { numbersToEn } from 'utils/tools';


const VerifyWithdrawCoin = ({ body, submit, close, type }: { body: any, submit: any, close: any, type: string }) => {
  const user = useUser();
  const dispatch = useDispatch();
  const [code, setCode] = useState<any>('');

  const send = () => {

    if (code?.trim()?.length==6) {
      const newBody: any = _.cloneDeep(body);
      newBody.code = numbersToEn(code?.toString());

      submit(newBody)
    }
    else {
      dispatch(errorAlert("کد تایید را وارد کنید"));
    }
  }

  return (
    <div className='popup'>
      <div className='closePop closePopWhite' onClick={() => dispatch(hideDialog())}></div>
      <div className='detailHistory verifyWithdrawCoin'>
        <div className='headerBox'></div>
        <div className='bodyDialog'>
          <div className='rules'>
            {type === 'sms' ? (
              <>
                کد تایید برای شماره <i>{user.profile.mobile}</i> پیامک شد
              </>
            ) : (
              <>
                کد تایید <i>Google Authenticator</i> را وارد نمایید
              </>
            )}
          </div>
          <div className='codeNumberBox'>
            <VerificationInput
              // onComplete={(e) => confirmAction(e)}
              onChange={(e) => { Number(e) >= 0 && setCode(e) }}
              value={code}
              placeholder='-'
              inputProps={{ type: "tel" }}
              classNames={{
                container: "containerVerify",
                character: "characterVerify",
                characterFilled: "character--filled",
                characterInactive: "character--inactive",
                characterSelected: "character--selected",
              }}
            />
          </div>
          <div className='spacer'></div>
        </div>
        <div className='btnsConfirmBuySellFast'>
          <button className="btnShowMore voucherBtn" onClick={send} > تایید  </button>
          <button className=" btnShowMore voucherBtn btnCancel" onClick={() => close()} > لغو  </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyWithdrawCoin;
