import PatchCode from 'components/Helper/PatchCode';
import SelectBox from 'components/Helper/SelectBox';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import useUser from 'hooks/useUser';
import { ICoin } from 'interfaces/global';
import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { zeroFormat, toFixed } from 'utils/numeral';
import { dotted, numbersToEn } from 'utils/tools';
import Address from './Address';
import { centerDialog } from 'services/reducer/dialog';
import AlarmMemo from '../Deposit/AlarmMemo';
import _ from 'lodash';
import useTicker from 'hooks/useTicker';
import VerificationInput from 'react-verification-input';

const Crypto = ({ symbol }: { symbol?: string }) => {
  const dispatch = useDispatch();
  const global = useGlobal();
  const user = useUser();
  const ticker = useTicker();
  const [step, setStep] = useState(0);
  const [type, setType] = useState('sms');
  const [code, setCode] = useState<any>('');
  const [amount, setAmount] = useState(0);
  const [address, setAddress] = useState('');
  const [data, setData] = useState<any>();
  const [coin, setCoin] = useState<ICoin | null>(global.coins[symbol!] || null);
  const [network, setNetwork] = useState('');
  const [needTagMemo, setNeedTagMemo] = useState(false);
  const [memo, setMemo] = useState('');

  const justEnglish = (e: any) => {
    const re = /^[^\u0600-\u06FF]+$/;
    if (e === "" || re.test(e)) {
      setAddress(e);
    } else {
      dispatch(errorAlert("فقط حروف لاتین برای آدرس کیف پول وارد کنید"));
    }
  };

  const justNum = (e: any) => {
    const re = /^[^\u0600-\u06FF]+$/;
    if (e === "" || re.test(e)) {
      setMemo(e);
    } else {
      dispatch(errorAlert("فقط اعداد و حروف لاتین برای آدرس ممو وارد کنید"));
    }
  };

  const handleMaxWithdraw = () => {
    const daily = Number(user.profile?.limit?.coin?.daily);
    const monthly = Number(user.profile?.limit?.coin?.monthly);
    const maxMonthly = Number(user.profile?.plane?.level?.withdraw?.coin?.monthly);
    const maxDaily = Number(user.profile?.plane?.level?.withdraw?.coin?.daily);

    const calcDaily = Number(maxDaily - daily) ?? 0;
    const calcMonthly = Number(maxMonthly - monthly) ?? 0;

    return { calcDaily, calcMonthly }
  }

  const saveAction = async () => {
    const newAmount = Number(numbersToEn(amount?.toString()))
    if (_.isEmpty(coin)) {
      dispatch(errorAlert("لطفا ارز خود را وارد کنید"));
      return;
    }

    if (network?.trim().length == 0) {
      dispatch(errorAlert("لطفا شبکه انتقال را انتخاب کنید"));
      return;
    }

    if (!coin?.isActive) {
      dispatch(errorAlert("ارز مورد نظر غیرفعال می باشد"));
      return;
    }

    if (!coin?.networks.find((e) => e.network.name === network)?.isActive) {
      dispatch(errorAlert("شبکه مورد نظر غیرفعال می باشد"));
      return;
    }

    if (coin.symbol?.toLocaleLowerCase() != "usdt" && !ticker.price(coin.symbol + 'usdt')) {
      dispatch(errorAlert("دردسترس نیست"));
      return
    }


    if (!coin?.networks.find((e) => e.network.name === network)?.withdraw?.isActive) {
      dispatch(errorAlert("این ارز در این شبکه قابلیت برداشت ندارد"));
      return
    }

    if (address.length < 20) {
      dispatch(errorAlert("آدرس مقصد را بررسی کنید"));
      return;
    }
    if (coin?.networks.find((ele: any) => ele.network.name === network)?.network?.regex &&
      !new RegExp(coin?.networks.find((ele: any) => ele.network.name === network)?.network?.regex).test(address)) {
      dispatch(errorAlert("آدرس مقصد را معتبر نیست"));
      return
    }

    if (newAmount < 0 || newAmount === 0 || newAmount < (coin?.networks.find((e) => e.network.name === network)?.withdraw.min ?? 0)) {
      dispatch(errorAlert("لطفا حداقل مقدار برداشت را رعایت کنید"));
      return;
    }

    if ((newAmount * ticker.price(coin.symbol + 'usdt')) > handleMaxWithdraw().calcDaily || (newAmount * ticker.price(coin.symbol + 'usdt')) > handleMaxWithdraw().calcMonthly) {
      dispatch(errorAlert(`مقدار برداشت درخواست شده بیش از سقف مجاز است`));
      return;
    }



    if (coin?.networks.find((e) => e.network.name === network)?.network?.memo?.isForce && memo?.trim().length <= 0) {
      dispatch(errorAlert(" تگ / ممو را وارد کنید"));
      return;
    }

    dispatch(showLoading());

    let body: any = {
      coin: coin?._id,
      network: coin?.networks.find((e) => e.network.name === network)?.network._id,
      receiver: address,
      amount: newAmount,
    }

    if (coin?.networks.find((e) => e.network.name === network)?.network?.memo?.isForce
      || coin?.networks.find((e) => e.network.name === network)?.network?.memo?.isNeed) {
      if (memo?.trim()?.length > 0) {
        body["memo"] = memo
      }
    }

    const result = await gather(`${config.data.api}/v1/withdraw`, true).post(body);

    dispatch(hideLoading());

    if (result.code === 200) {
      setStep(2);
      setType(result.data.type);
      window.scrollTo(0, 0);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  const hanleSelectNetwork = (e: any) => {
    setNeedTagMemo(false);
    setAddress("");
    setMemo("");
    setNetwork(e);

    if (coin?.networks.find((ele: any) => ele.network.name === e)?.network?.memo?.isForce
      || coin?.networks.find((ele: any) => ele.network.name === e)?.network?.memo?.isNeed) {
      setNeedTagMemo(true);
      dispatch(centerDialog(<AlarmMemo symbol={e} depo={false} />));
    }
  }

  const confirmAction = async (code1: any) => {
    dispatch(showLoading());

    let body: any = {
      coin: coin?._id,
      network: coin?.networks.find((e) => e.network.name === network)?.network._id,
      receiver: address,
      amount: Number(numbersToEn(amount?.toString())),
      code: numbersToEn(code1?.toString()),
    }

    if (coin?.networks.find((e) => e.network.name === network)?.network?.memo?.isForce
      || coin?.networks.find((e) => e.network.name === network)?.network?.memo?.isNeed) {
      if (memo?.trim()?.length > 0) {
        body["memo"] = memo
      }
    }

    const result = await gather(`${config.data.api}/v1/withdraw/confirm`, true).post(body);

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert('درخواست با موفقیت ثبت شد'));
      dispatch(hideDrawer());
      setNeedTagMemo(false);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  useEffect(() => {
    if (data) {
      if (coin?._id == data?.coin?._id) {
        setCoin(global.coins[data?.coin.symbol]);
        justEnglish(data?.receiver);
        if (data?.memo && data?.memo?.trim().length > 0) {
          justNum(data?.memo);
        }

        setTimeout(() => {
          setNetwork(global.coins[data?.coin.symbol]?.networks.find((e) => e.network._id === data.network._id)?.network?.name!);
        }, 10);
      } else {
        setAddress('');
        setMemo('');
        setNetwork('');
        dispatch(errorAlert('آدرس وارد شده با ارز انتخابی  یکسان نیست'));
      }
    }
  }, [data]);

  useEffect(() => {
    setNetwork('');
    setNeedTagMemo(false);
  }, [coin]);

  const fee = coin?.networks.find((e) => e.network.name.toLowerCase() === network.toLowerCase())?.withdraw.fee || 0;
  const max = toFixed((user.profile.limit.coin.daily / user.profile.plane.level.withdraw.coin.daily) * 100 || 0, 2);
  const Remainder = user.profile.plane.level.withdraw.coin.daily - user.profile.limit.coin.daily;

  return step === 0 ? (
    <>
      <div className='step stepDeposit'>
        <i>۱</i>
        <h3>انتخاب ارز</h3>
      </div>
      <SelectBox
        data={global.coins}
        symbol={""}
        search={true}
        value={
          coin ? (
            <>
              <img crossOrigin='anonymous' src={coin.icon} />
              <h3>
                {coin.name} ({coin.symbol.toUpperCase()})
              </h3>
            </>
          ) : (
            <>ارز خود را انتخاب کنید</>
          )
        }
        template='coin'
        setSelected={setCoin}
      />
      <div className='step stepDeposit'>
        <i>۲</i>
        <h3>انتخاب شبکه</h3>
      </div>
      <SelectBox
        data={coin?.networks}
        search={false}
        value={network ? <h3>{network}</h3> : <>نوع شبکه را انتخاب کنید</>}
        template='network'
        symbol={coin?.symbol.toUpperCase()}
        setSelected={hanleSelectNetwork}
      />
      <div className='step stepDeposit'>
        <i>۳</i>
        <h3>آدرس مقصد</h3>
        <section className='addCard addCardAddress2' onClick={() => dispatch(fullDrawer(<Address setData={setData} />))}>
          انتخاب از لیست
        </section>
      </div>
      <div className='mainInput'>
        <div>
          <span className='address'></span>
          <input type='text' value={address} onChange={(e) => justEnglish(e.target.value)} />
        </div>
      </div>
      <div className='step stepDeposit'>
        <i>۴</i>
        <h3>مقدار برداشت</h3>
        {coin && (
          <section className='addCard addCardNo' onClick={() => setAmount(user.balance.asset[coin.symbol]?.free || 0)}>
            موجودی:{' '}
            <b className='inlinePrice'>
              {zeroFormat(user.balance.asset[coin.symbol]?.free || 0)} {coin.symbol.toUpperCase()}
            </b>
          </section>
        )}
      </div>
      <div className='mainInput'>
        <div>
          <NumericFormat
            inputMode='decimal'
            className='ltr textRight'
            value={amount > 0 ? amount : ''}
            allowNegative={false}
            decimalScale={coin?.decimal}
            thousandSeparator={true}
            onValueChange={(e) => setAmount(Number(e.value))}
            placeholder='0.0'
          />
        </div>
      </div>
      {needTagMemo
        && <>
          <div className='step stepDeposit'>
            <i>۵</i>
            <h3>memo / ممو</h3>
          </div>
          <div className='mainInput'>
            <div>
              <span className='address'></span>
              <input type='text' value={memo} onChange={(e) => justNum(e.target.value)} />
            </div>
          </div>
        </>}
      {coin && network && (
        <div className='listCashCode listCashTop'>
          <section>
            <div>
              <span>کارمزد برداشت</span>
              <span className='inlinePrice'>
                {zeroFormat(fee)}
                {coin?.symbol.toUpperCase()}
              </span>
            </div>
            <div>
              <span>حداقل مقدار برداشت</span>
              <span className='inlinePrice'>
                {zeroFormat(coin.networks.find((e) => e.network.name.toLowerCase() === network.toLowerCase())?.withdraw.min || 0)}
                {coin?.symbol.toUpperCase()}
              </span>
            </div>
            <div>
              <span>مقدار دلاری</span>
              <span className='inlinePrice'>
                {zeroFormat(Number(Number(numbersToEn(amount?.toString())) * ticker.price(coin.symbol + 'usdt')), 0, false)}
              </span>
            </div>
            <div>
              <span>مقدار نهایی</span>
              <span className='inlinePrice'>
                {Number(Number(numbersToEn(amount?.toString())) - fee) > 0 ? zeroFormat(toFixed(Number(numbersToEn(amount?.toString())) - fee, coin?.decimal)) : 0}
                {coin?.symbol.toUpperCase()}
              </span>
            </div>
          </section>
        </div>
      )}
      <div className='spacer'></div>
      <div className='largeButton largeButtonGreen' onClick={() => saveAction()}>
        درخواست برداشت
      </div>
      <div className='listCashCode listMax'>
        <section>
          <div>
            <span>سقف برداشت شما (روزانه):</span>
            <span>{zeroFormat(user.profile.plane.level.withdraw.coin.daily)} دلار</span>
          </div>
          <div>
            <span>برداشت شده:</span>
            <span>{zeroFormat(user.profile.limit.coin.daily)} دلار</span>
          </div>
        </section>
      </div>
      <div className='maxWithdraw'>
        <h4>{max}% سقف روزانه استفاده شده</h4>
        <section>
          <span style={{ width: max + '%' }}></span>
        </section>
      </div>
      <div className='remainderMaxWithdraw'>
        <span className='t1'>باقی مانده سقف روزانه :</span>
        <span className='t2'>{zeroFormat(Remainder)} دلار</span>
      </div>
    </>
  ) : (
    <>
      <div className='voucherData voucherDataColor'>
        <div className='listCashCode listCashCodeCustom'>
          <section>
            <div>
              <span>ارز</span>
              <span className='currencyDetail'>
                {coin?.name} ({coin?.symbol.toUpperCase()})
                <img crossOrigin='anonymous' src={global.coins[coin?.symbol!].icon} />
              </span>
            </div>
          </section>
          <section>
            <div>
              <span>مقدار</span>
              <span className='ltr'>{Number(numbersToEn(amount?.toString()))} {coin?.symbol.toUpperCase()}</span>
            </div>
          </section>
          <section>
            <div>
              <span>کارمزد شبکه</span>
              <span className='ltr'>{fee} {coin?.symbol.toUpperCase()}</span>
            </div>
          </section>
          <section>
            <div>
              <span>مقدار نهایی</span>
              <span className='ltr'>{Number(numbersToEn(amount?.toString())) - fee} {coin?.symbol.toUpperCase()}</span>
            </div>
          </section>
          <section>
            <div>
              <span>دریافت کننده</span>
              <i></i>
              <span className='ltr'>{dotted(address)}</span>
            </div>
          </section>
          {needTagMemo &&
            <section>
              <div>
                <span>memo / ممو</span>
                <i></i>
                <span className='ltr'>{memo}</span>
              </div>
            </section>}
          <section>
            <div>
              <span>تاریخ</span>
              <span className='ltr'>
                {moment()
                  .locale('fa')
                  .format('YYYY-MM-DD , HH:mm:ss')}
              </span>
            </div>
          </section>
        </div>
      </div>
      <div className='rules newRules'>
        {type === 'sms' ? (
          <>
            کد تایید جهت برداشت دارایی به شماره <i>{user.profile.mobile}</i> پیامک شد , لطفا کد پیامک شده را وارد کنید
          </>
        ) : (
          <>
            کد تایید <i>Google Authenticator</i> را وارد نمایید
          </>
        )}
      </div>
      <div className='codeNumberBox'>
        <VerificationInput
          onComplete={(e) => confirmAction(e)}
          onChange={(e) => { Number(e) >= 0 && setCode(e) }}
          value={code}
          placeholder='-'
          inputProps={{ type: "tel" }}
          classNames={{
            container: "containerVerify",
            character: "characterVerify",
            characterFilled: "character--filled",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
        />
      </div>
      <div className='spacer'></div>
      <div className='largeButton largeButtonGreen' onClick={() => confirmAction(code)}>
        تایید درخواست برداشت
      </div>
    </>
  );
};

export default Crypto;
