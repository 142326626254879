import useGlobal from 'hooks/useGlobal';
import { IAsset } from 'interfaces/global';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hideDialog } from 'services/reducer/dialog';

const Trade = ({ data }: { data: IAsset }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const global = useGlobal();

  return (
    <div className='popup selectTrade'>
      <h6>
        انتخاب نوع معامله {data.symbol}
        <img crossOrigin='anonymous' src={data.icon} />
      </h6>
      <div>
        <section className='btnTrade'
          onClick={() => {
            dispatch(hideDialog());
            navigate('/order/fast/' + data.symbol?.toUpperCase(), { state: { default: Object.values(global.coins).find((e) => e.symbol === data.symbol) } });
          }}>
          <i className="fastMarket"></i>
          <span>
            مبادله فوری
          </span>
        </section>
        <section className='btnTrade'
          onClick={() => {
            dispatch(hideDialog());
            navigate('/order/market/' + data.symbol?.toUpperCase() + "-TMN", { state: { default: Object.values(global.coins).find((e) => e.symbol === data.symbol) } });
          }}>
          <i className="advanceMarket"></i>
          <span>
            مبادله حرفه ای
          </span>

        </section>
      </div>
    </div>
  );
};

export default Trade;
