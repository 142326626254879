import SelectBox from 'components/Helper/SelectBox';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import { ICoin } from 'interfaces/global';
import { useQRCode } from 'next-qrcode';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { centerDialog } from 'services/reducer/dialog';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import AlarmMemo from './AlarmMemo';
import _ from 'lodash';

const Crypto = ({ symbol }: { symbol?: string }) => {
  const dispatch = useDispatch();
  const global = useGlobal();
  const [address, setAddress] = useState('');
  const [memo, setMemo] = useState('');
  const [showMemo, setShowMemo] = useState(false);
  const [coin, setCoin] = useState<ICoin | null>(global.coins[symbol!] || null);
  const [network, setNetwork] = useState('');
  const { Image } = useQRCode();

  const copy = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch(successAlert('کپی شد'));
    } catch (error) {}
  };

  const saveAction = async () => {
    if (_.isEmpty(coin)) {
      dispatch(errorAlert("لطفا ارز خود را وارد کنید"));
      return;
    }

    if (!coin?.isActive) {
      dispatch(errorAlert("ارز مورد نظر غیرفعال می باشد"));
      return;
    }

    if (!coin?.networks.find((e) => e.network.name === network)?.isActive) {
      dispatch(errorAlert("شبکه مورد نظر غیرفعال می باشد"));
      return;
    }

    if (!coin?.networks.find((e) => e.network.name === network)?.deposit?.isActive) {
      dispatch(errorAlert("این ارز در این شبکه قابلیت واریز ندارد"));
      return
    }

    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/deposit/address`, true).post({
      coin: coin?._id,
      network: coin?.networks.find((e) => e.network.name === network)?.network._id,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      setAddress(result?.data?.address);
      if (result?.data?.memo) {
        setMemo(result.data.memo);
        dispatch(centerDialog(<AlarmMemo symbol={network} depo={true} />));
        setShowMemo(true);
      }
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  useEffect(() => {
    setAddress('');
    setMemo('');
    setNetwork('');
    setShowMemo(false);
  }, [coin]);

  useEffect(() => {
    setShowMemo(false);
    if (network) {
      saveAction();
    }
  }, [network]);

  return (
    <>
      <div className='step stepDeposit'>
        <i>۱</i>
        <h3>انتخاب ارز</h3>
      </div>
      <SelectBox
        data={global.coins}
        search={true}
        symbol={""}
        value={
          coin ? (
            <>
              <img crossOrigin='anonymous' src={coin.icon} />
              <h3>
                {coin.name} ({coin.symbol.toUpperCase()})
              </h3>
            </>
          ) : (
            <>ارز خود را انتخاب کنید</>
          )
        }
        template='coin'
        setSelected={setCoin}
      />
      <div className='step stepDeposit'>
        <i>۲</i>
        <h3>انتخاب شبکه</h3>
      </div>
      <SelectBox
        symbol={coin?.symbol.toUpperCase()}
        data={coin?.networks}
        search={false}
        value={network ? <h3>{network}</h3> : <>نوع شبکه را انتخاب کنید</>}
        template='networkDep'
        setSelected={setNetwork}
      />
      <div className='parentMemo'>
        {showMemo && (
          <div className='alarmMemo'>
            شناسه [تگ / ممو] را در پلت فرم برداشت به درستی پر کنید، در غیر این صورت سپرده {coin?.symbol?.toUpperCase()} شما از بین خواهد رفت.
            <i onClick={() => setShowMemo(false)}>تایید می کنم</i>
          </div>
        )}
        {address && (
          <div className='addressShow' onClick={() => copy(address)}>
            <Image text={address} options={{ type: 'image/jpeg', quality: 1, margin: 0, width: 120 }} />
            <div>
              <h4>
                آدرس واریز {coin?.nameFa} در شبکه {network}
              </h4>
              <section>
                <i className='copy'></i>
                <b>{address.split('').join(' ')}</b>
              </section>
            </div>
          </div>
        )}
        {memo && (
          <>
            <h4 className='alertMemo'>در صورت وارد نکردن MEMO در هنگام واریز، دارایی شما از دست خواهد رفت</h4>
            <div className='addressShow memoShow' onClick={() => copy(memo)}>
              <Image text={memo} options={{ type: 'image/jpeg', quality: 1, margin: 0, width: 120 }} />
              <i className='copy'></i>
              <b>
                MEMO/TAG
                <br />
                {memo}
              </b>
            </div>
          </>
        )}
      </div>
      <div className='spacer'></div>
    </>
  );
};

export default Crypto;
