import PatchCode from 'components/Helper/PatchCode';
import config from 'config';
import { IDispatch } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import VerificationInput from 'react-verification-input';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getProfile } from 'services/reducer/user';
import gather from 'utils/gather';
import { numbersToEn } from 'utils/tools';

const Code = () => {
  const [code, setCode] = useState<any>('');
  const dispatch = useDispatch<IDispatch>();

  const confirmAction = async (code1:any) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/gAuth/confirm`, true).post({
      code: numbersToEn(code1?.toString()),
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      await dispatch(getProfile());
      dispatch(successAlert('فعال سازی با موفقیت انجام شد'));
      dispatch(hideDrawer());
    } else {
      setCode([]);
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  return (
    <>
      <div className='emailLogo gLogo'></div>
      <div className='rules rulesCenter rulesCenterNo'>
        جهت تایید صحت فرایند فعال‌سازی کد دوعاملی عدد 6 رقمی نمایش داده شده در اپلیکیشن <i>Google Authenticator</i> را وارد کنید.
      </div>
      <div className='codeNumberBox'>
        <VerificationInput
          onComplete={(e) => confirmAction(e)}
          onChange={(e) => { Number(e) >= 0 && setCode(e) }}
          value={code}
          placeholder='-'
          inputProps={{ type: "tel" }}
          classNames={{
            container: "containerVerify",
            character: "characterVerify",
            characterFilled: "character--filled",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
        />
      </div>
      <div className='largeButton' onClick={() => confirmAction(code)}>
        تایید و ادامه
      </div>
    </>
  );
};

export default Code;
