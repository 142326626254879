import Top from 'components/Layouts/Top';
import Uploader from 'components/Layouts/Uploader';
import config from 'config';
import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { centerDialog } from 'services/reducer/dialog';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const View = ({ data }: any) => {
  const dispatch = useDispatch();
  const [tickets, setTickets] = useState([]);
  const [reply, setReply] = useState('');
  const [src, setSrc] = useState('');

  const repliesRender = () => {
    const rows: JSX.Element[] = [];

    tickets
      .sort((a: any, b: any) => a.createdAt - b.createdAt)
      .forEach((e: any) => {
        rows.push(
          <div className={`reply_${e.type}`}>
            <h4>توسط {e.type === 'user' ? 'شما' : e.sender.name + ' ' + e.sender.family}</h4>
            <span dangerouslySetInnerHTML={{ __html: e?.text.replace(/\n/g, '<br />') }}></span>
            {e.file && <img onClick={() => dispatch(centerDialog(<img src={e.file} style={{ maxHeight: '320px', maxWidth: '320px' }} />))} src={e.file} />}
            <i>
              {moment(e.createdAt)
                .locale('fa')
                .format('YYYY-MM-DD HH:mm:ss')}
            </i>
          </div>,
        );
      });

    return rows;
  };

  const saveAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/ticket/${data._id}/reply`, true).post({
      text: reply?.trim()?.length==0?"فایل ضمیمه شد":reply,
      file: src,
    });

    if (result.code === 200) {
      dispatch(successAlert('پاسخ با موفقیت ثبت شد'));
      dispatch(hideDrawer());
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/ticket/${data._id}/reply`, true).get();
    if (result.code === 200) {
      const res = [
        {
          type: 'user',
          text: data.text,
          createdAt: data.createdAt,
          file: data.file,
        },
      ].concat(result.data) as any;

      setTickets(res);
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={data.title} />
        <div className='container inData'>
          <div className='replies'>{repliesRender()}</div>
          <div className='mainInput'>
            <p>پاسخ</p>
            <div>
              <span className='id'></span>
              <textarea className='rtl' onChange={(e) => setReply(e.target.value)} />
            </div>
          </div>
          <Uploader src={src} setSrc={setSrc} />
          <div className='spacer'></div>
          <div className='largeButton' onClick={() => saveAction()}>
            ثبت پاسخ
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
