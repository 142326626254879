import React, { useState, useEffect } from 'react';
import '../assets/css/offlineNotification.css';

const OfflineNotification = () => {
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // پاکسازی رویدادها
    return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      {isOffline &&
        <div className="offline-notification">
          <p>عدم اتصال به اینترنت !</p>
        </div>
      }
    </>
  );
};

export default OfflineNotification;
