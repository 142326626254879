import Tabs from 'components/Helper/Tabs';
import Top from 'components/Layouts/Top';
import config from 'config';
import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { statusColor } from 'utils/color';
import gather from 'utils/gather';
import { statusTicket } from 'utils/translate';
import View from './View';

const statusTitles = {
  open: 'تیکت باز',
  all: 'همه',
};

const Ticket = () => {
  const [tab, setTab] = useState(statusTitles.open);
  const [tickets, setTickets] = useState([]);
  const dispatch = useDispatch();

  const ticketsRender = () => {
    const rows: JSX.Element[] = [];

    tickets
      .sort((a: any, b: any) => b.updatedAt - a.updatedAt)
      .forEach((e: any) => {
        rows.push(
          <div onClick={() => dispatch(fullDrawer(<View data={e} />))}>
            <span className='f1 textRight'>
              {e.title}
              <i>{moment(e.updatedAt).locale('fa').format('YYYY-MM-DD HH:mm:ss')}</i>
            </span>
            <span className={`boxStatus bg${statusColor(e.status)}`}>{statusTicket(e.status)}</span>
          </div>,
        );
      });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return (
      <div className='openOrder allTicket'>
        <div>
          <span className='f1'>عنوان</span>
          <span>وضعیت</span>
        </div>
        {rows}
      </div>
    );
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/ticket`, true).get();
    if (result.code === 200) {
      setTickets(result.data);
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'تیکت ها'} />
        <div className='container inData'>
          <div className='tabs tabsTop'>
            <Tabs titles={statusTitles} selected={tab} setSelected={setTab} />
          </div>
          <div className='items itemsFull itemsTicket'>{ticketsRender()}</div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
