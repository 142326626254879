import { useEffect, useState } from 'react';

const useTimer = () => {
  const [isFinish, setIsFinish] = useState(true);
  const [minute, setMinute] = useState('00');
  const [second, setSecond] = useState('00');

  let max = 0;
  let interval: NodeJS.Timeout;

  const counter = () => {
    setMinute((`0${Math.floor(max / 60)}`).slice(-2));
    setSecond((`0${Math.floor(max % 60)}`).slice(-2));

    max--;
    if (max < 0) {
      clearInterval(interval);
      stop();
    }
  }

  const start = (time: number = 240) => {
    setIsFinish(false);

    if (interval) {
      clearInterval(interval);
    }

    max = time;

    counter();
    interval = setInterval(() => {
      counter();
    }, 1000);
  }

  const stop = () => {
    setIsFinish(true);
    clearInterval(interval);
  }

  useEffect(() => {
    return () => {
      stop();
    }
  }, []);

  return { 
    isFinish, 
    minute, 
    second, 
    start, 
    stop, 
  }
}

export default useTimer;
