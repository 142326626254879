import config from 'config';
import { IDispatch } from 'interfaces/global';
import { IForm } from 'interfaces/googleAuth';
import { useQRCode } from 'next-qrcode';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Form = ({ setStep }: IForm) => {
  const [qr, setQr] = useState('');
  const [secret, setSecret] = useState('');
  const { Image } = useQRCode();
  const dispatch = useDispatch<IDispatch>();

  const copy = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch(successAlert('کپی شد'));
    } catch (error) { }
  };


  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/gAuth`, true).get();
    if (result.code === 200) {
      setQr(result.data.qrCode);
      setSecret(result.data.secret);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <div className='emailLogo gLogo'></div>
      <div className='rules rulesCenter'>کد زیر را در برنامه Google Authenticator اسکن نمایید</div>
      <div className='upQR'>{qr && <Image text={qr} options={{ type: 'image/jpeg', quality: 1, margin: 2, width: 120 }} />}</div>
      <section className='gCopy' onClick={() => secret && copy(secret?.toString())}>
        <i className='copy'></i>
        <b>{secret}</b>
      </section>
      <div className='largeButton' onClick={() => setStep(3)}>
        تایید و ادامه
      </div>
    </>
  );
};

export default Form;
