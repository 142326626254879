import Login from 'components/Modules/Login';
import { useDispatch } from 'react-redux';
import { hideDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';

const PreLogin = () => {
  const dispatch = useDispatch();

  return (
    <div className='popup preData'>
      <div className='preLogo'></div>
      <h4>جهت استفاده از خدمات پلتفرم اکسنوین وارد شوید</h4>
      <div
        className='largeButton'
        onClick={() => {
          dispatch(hideDialog());
          setTimeout(() => {
            dispatch(fullDrawer(<Login />));
          }, 200);
        }}
      >
        ورود / عضویت
      </div>
    </div>
  );
};

export default PreLogin;
