import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import { IDeposit } from 'interfaces/detail';
import moment from 'jalali-moment';
import { useDispatch } from 'react-redux';
import { hideDialog } from 'services/reducer/dialog';
import { useLocalStorage } from 'usehooks-ts';
import { typeColor } from 'utils/color';
import { zeroFormat, toFixed } from 'utils/numeral';
import { sideTranslate } from 'utils/translate';


const ConfirmBuySellFast = ({ data, send, close }: { data: any, send: any, close: any }) => {
  const global = useGlobal();
  const ticker = useTicker()
  const dispatch = useDispatch();
  const [confiemOrder, setConfiemOrder] = useLocalStorage('confiemOrder', "false");

  const dontShowDialog = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfiemOrder(event.target.checked + "")
  }

  const handelSend = () => {
    send({
      'coin': data?.coin._id,
      'pair': 'tmn',
      'side': data?.side,
      'price': ticker[data?.side == "buy" ? "buy" : "sell"](data?.coin?.symbol + 'tmn'),
      "amount": data?.side == "buy"
        ? toFixed(data?.myBuySell / ticker['buy'](data?.coin?.symbol + 'tmn'), data?.coin?.decimal)
        : toFixed(data?.amount, data?.coin?.decimal)
      ,
      "trade": "otc",
      "stop": null
    })

  }


  return (
    <div className='popup'>
      <div className='closePop closePopWhite' onClick={() => dispatch(hideDialog())}></div>
      <div className='detailHistory'>
        <div className='headerBox'></div>
        <div className='voucherData'>
          <div className='listCashCode listCashCodeCustom'>
            <section>
              <div>
                <span>نوع سفارش</span>
                <span className={`color${typeColor(data.side)}`}>{sideTranslate(data.side)}</span>
              </div>
            </section>
            {/* <section>
              <div>
                <span>نحوه سفارش</span>
                <span><p >سفارش سریع</p></span>
              </div>
            </section>
            <section>
              <div>
                <span>تاریخ</span>
                <span className='ltr'>
                  {moment().locale("fa").format('HH:mm  YYYY-MM-DD')}
                </span>
              </div>
            </section> */}
            <section>
              <div>
                <span>ارز</span>
                <span className='currencyDetail'>
                  {data.coin.name} ({data.coin.symbol.toUpperCase()})
                  <img crossOrigin='anonymous' src={global.coins[data.coin.symbol].icon} />
                </span>
              </div>
            </section>
            <section>
              <div>
                <span>مقدار</span>
                <span className='ltr'>
                  {data?.side == "buy"
                    ? zeroFormat(toFixed(data?.myBuySell / ticker['buy'](data?.coin?.symbol + 'tmn'), data?.coin?.decimal))
                    : zeroFormat(toFixed(data?.amount, data?.coin?.decimal))}  &nbsp;{data.coin.symbol.toUpperCase()}
                </span>
              </div>
            </section>
            <section>
              <div>
                <span>قیمت</span>
                <span className='ltr'>
                  {zeroFormat(ticker[data?.side == "buy" ? "buy" : 'sell'](data?.coin?.symbol + 'tmn'))}&nbsp; TMN
                </span>
              </div>
            </section>
            <section>
              <div>
                <span>مجموع</span>
                <span className='ltr'>
                  {data?.side == "buy"
                    ? zeroFormat(toFixed(data?.myBuySell, data?.side == "buy" ? 0 : data?.coin?.decimal))
                    : zeroFormat(toFixed(data?.amount * ticker['sell'](data?.coin?.symbol + 'tmn'), data?.coin?.decimal))}
                  &nbsp; TMN
                </span>
              </div>
            </section>
            {data?.radioBuy !== "defualt"
              && <>
                <section>
                  <div>
                    <span>شبکه</span>
                    <span >{data?.network?.network?.name}</span>
                  </div>
                </section>
                <section>
                  <div>
                    <span>آدرس کیف پول</span>
                    <span className='ltr'>{data?.address}</span>
                  </div>
                </section>
                {data?.needTagMemo
                  && <section>
                    <div>
                      <span>ممو</span>
                      <span >{data?.tagMemo}</span>
                    </div>
                  </section>}
              </>
            }
          </div>
        </div>
        <div className='borderHex'></div>
        <div className='warningOrderFast'>
          <p>کاربر گرامی به دلیل نوسانات قیمت  مقدار دریافتی شما به صورت تقریبی محاسبه گردیده است</p>
        </div>
        <div className='checkBoxConfirmBuySellFast'>
          <input className='checkbox' onChange={dontShowDialog} type="checkbox" name="" id="checkId" />
          <label htmlFor="checkId">این تاییدیه را در معاملات بعد نشان نده</label>
        </div>
        <div className='btnsConfirmBuySellFast'>
          <button className="btnShowMore voucherBtn" onClick={handelSend} > تایید  </button>
          <button className=" btnShowMore voucherBtn btnCancel" onClick={() => close()} > لغو  </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBuySellFast;
