import LevelList from 'components/Layouts/LevelList';
import AddEmail from 'components/Layouts/profile/AddEmail';
import QustionAddOrEditEmail from 'components/Layouts/profile/QustionAddOrEditEmail';
import VerifyCodeEmailPhone from 'components/Layouts/profile/VerifyCodeEmailPhone';
import Warning from 'components/Layouts/profile/Warning';
import WarningEmail from 'components/Layouts/profile/WarningEmail';
import Top from 'components/Layouts/Top';
import useUser from 'hooks/useUser';
import moment from 'jalali-moment';
import { useDispatch } from 'react-redux';
import { centerDialog } from 'services/reducer/dialog';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';

const Profile = () => {
  const dispatch = useDispatch();
  const { profile } = useUser();

  const openMenu = (content: JSX.Element) => {
    dispatch(hideDrawer());
    setTimeout(() => {
      dispatch(fullDrawer(content));
    }, 300);
  };

  const handelAddEditEmail = async () => {
    if (profile?.verify?.email) {
      dispatch(centerDialog(<WarningEmail oldEmail={profile?.email} />))
    } else if (profile?.email) {
      dispatch(centerDialog(<QustionAddOrEditEmail value={profile?.email} />))
    } else {
      openMenu(<AddEmail />)
    }
  }

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'پروفایل'} />
        <div className='container inData profileUser'>
          <div className='mainInput'>
            <p>نام و نام خانوادگی</p>
            <div>
              <input
                readOnly
                type="text"
                autoComplete='one-time-code'
                value={(profile?.name ?? "-") + " " + (profile?.family ?? "-")}
              />
              <span className='userprofile'></span>
            </div>
          </div>
          <div className='mainInput'>
            <p>کدملی</p>
            <div>
              <input
                readOnly
                type="text"
                autoComplete='one-time-code'
                value={profile?.nationalCode ?? "--"}
              />
              <span className='id'></span>
            </div>
          </div>
          <div className='mainInput'>
            <p>تاریخ تولد</p>
            <div>
              <input
                readOnly
                type="text"
                autoComplete='one-time-code'
                value={profile?.birthDate ?profile?.birthDate.startsWith("13")?profile?.birthDate: moment.from(profile?.birthDate, 'YYYY-MM-DD').locale("fa").format('YYYY/MM/DD') : ''}
              />
              <span className='birthday'></span>
            </div>
          </div>
          <div className='mainInput mainEditInput'>
            <section className='topTxt'>
              <p>پست الکترونیک</p>
              <span className={`status ${profile?.verify?.email && "active"}`}>تایید {profile?.verify?.email ? "شده" : "نشده"}</span>
            </section>
            <div>
              <i className="editNum"  onClick={handelAddEditEmail}></i>
              <input
                readOnly
                type="text"
                autoComplete='one-time-code'
                value={profile?.email ?? "--"}
              />
              <span className='email2'></span>
            </div>
          </div>
          <div className='mainInput mainEditInput'>
            <section className='topTxt'>
              <p>شماره تماس</p>
              <span className={`status ${profile?.verify?.mobile && "active"}`}>تایید {profile?.verify?.mobile ? "شده" : "نشده"}</span>
            </section>

            <div>
              <i className="editNum" onClick={()=>dispatch(centerDialog(<Warning oldPhone={profile?.mobile} />))}></i>
              <input
                readOnly
                type="text"
                autoComplete='one-time-code'
                value={profile?.mobile ?? "--"}
              />
              <span></span>
            </div>
          </div>
          <div className='txtBox'>
            <span className='t1'>ارتقا حساب کاربری</span>
            <p className='t2'>
              در صورتی که به سقف برداشت روزانه/ماهانه بیشتر نیاز دارید ، از بخش ارتقا حساب کاربری ، درخواست خود را ارسال کنید.
            </p>
          </div>
          {/* <div className='spacer'></div> */}
          <div className='largeButton' onClick={() => openMenu(<LevelList />)}>
            ارتقا حساب کاربری
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
