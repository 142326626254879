import Tabs from 'components/Helper/Tabs';
import Top from 'components/Layouts/Top';
import config from 'config';
import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const statusTitles = {
  unread: 'خوانده نشده',
  all: 'همه',
};

const Alarms = ({reloadApi}:{reloadApi:any}) => {
  const [tab, setTab] = useState(statusTitles.unread);
  const [tickets, setTickets] = useState([]);
  const dispatch = useDispatch();

  let filter = tickets;
  if (tab === statusTitles.unread) {
    filter = filter.filter((e: any) => !e.isRead);
  }

  const ticketsRender = () => {
    const rows: JSX.Element[] = [];

    filter
      .sort((a: any, b: any) => b.updatedAt - a.updatedAt)
      .forEach((e: any, i) => {
        rows.push(
          <div key={i} className={!e.isRead?"noSee":""}>
            {e.text}
            <i>
              {moment(e.updatedAt)
                .locale('fa')
                .format('ddd MMM yy ساعت HH:mm')}
            </i>
            {!e.isRead && <span className='notRead'>خوانده نشده</span>}
          </div>,
        );
      });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/alarm`, true).get();
    if (result.code === 200) {
      setTickets(result.data);
      dispatch(hideLoading());

      await gather(`${config.data.api}/v1/alarm/read`, true).get();
      reloadApi();
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'اطلاعیه ها'} />
        <div className='container inData'>
          <div className='tabs tabsTop'>
            <Tabs titles={statusTitles} selected={tab} setSelected={setTab} />
          </div>
          <div className='items itemsFull alarmsData'>{ticketsRender()}</div>
        </div>
      </div>
    </div>
  );
};

export default Alarms;
