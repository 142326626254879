import PatchCode from 'components/Helper/PatchCode';
import config from 'config';
import { IDispatch } from 'interfaces/global';
import { IForm } from 'interfaces/identity';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import VerificationInput from 'react-verification-input';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getProfile } from 'services/reducer/user';
import gather from 'utils/gather';
import { numbersToEn } from 'utils/tools';

const Form = ({ setStep }: IForm) => {
  const [code, setCode] = useState<any>('');
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch<IDispatch>();

  const acceptAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/rules`, true).get();
    if (result.code === 200) {
      setCurrent(1);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  const checkAction = async (code1:any) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/rules/confirm`, true).post({
      code: numbersToEn(code1?.toString()),
    });

    if (result.code === 200) {
      await dispatch(getProfile());
      setStep(2);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  return (
    <>
      <div className='emailLogo smsLogo smsLogoNew'></div>
      <div className='rules rulesCenter'>
        جهت ارتقا سطح کاربری، یک کد تایید به شما پیامک خواهد شد.
        <br />
        با تایید این کد، شما محتوای پیامک را خواهید پذیرفت.
      </div>
      {current === 0 ? (
        <>
          <div className='largeButton' onClick={() => acceptAction()}>
            ارسال کد تایید
          </div>
        </>
      ) : (
        <>
          <div className='rules rulesCenter'>
            <i>کد ارسال شده را وارد نمایید</i>
          </div>
          <div className='codeNumberBox'>
        <VerificationInput
          onComplete={(e) => checkAction(e)}
          onChange={(e) => { Number(e) >= 0 && setCode(e) }}
          value={code}
          placeholder='-'
          inputProps={{ type: "tel" }}
          classNames={{
            container: "containerVerify",
            character: "characterVerify",
            characterFilled: "character--filled",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
        />
      </div>
          <div className='largeButton' onClick={() => checkAction(code)}>
            ثبت و تایید کد
          </div>
        </>
      )}
    </>
  );
};

export default Form;
