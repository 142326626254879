import Top from 'components/Layouts/Top';
import config from 'config';
import { IDispatch } from 'interfaces/global';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getProfile } from 'services/reducer/user';
import gather from 'utils/gather';

const Level2 = () => {
  const dispatch = useDispatch<IDispatch>();
  const [src, setSrc]: any = useState();
  const [text, setText]: any = useState('');
  const [docName, setDocName] = useState("اینجا کلیک کنید یا فایل را بکشید");

  const acceptAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/levelUp`, true).get();
    if (result.code === 200) {
      dispatch(successAlert('درخواست شما با موفقیت ثبت شد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  const sendImg = async () => {
    if (!src) {
      dispatch(errorAlert("لطفا مدارک خود را بارگذاری کنید"));
      return;
    }

    if (text?.trim()?.length < 2) {
      dispatch(errorAlert("لطفا متن درخواست را وارد کنید"));
      return;
    }

    setDocName("اینجا کلیک کنید یا فایل را بکشید")
    dispatch(showLoading());

    const form_data = new FormData();
    form_data.append("type", "signature");
    form_data.append("doc", src);

    const result = await gather(`${config.data.api}/v1/file`,true).upload(form_data);

    if (result.code === 200) {
      setDocName(src?.name??"فایل با موفقیت ارسال شد")
      sendUpgrade(result?.data?.link)
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
      dispatch(hideLoading());
      setDocName("خطا در ارسال دوباره تلاش کنید")
    }

  }

  const sendUpgrade = async (link: any) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/sign`,true).put({ text: text, signature: link });

    if (result.code === 200) {
      acceptAction()
      await dispatch(getProfile());
      // dispatch(DialogShow.show(<ConfirmUpgrade text={"درخواست شما جهت بررسی ارسال شد"} close={() => dispatch(DialogShow.hide())} />))
      // setChange(true);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  }


  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'ارتقا سطح حساب'} />
        <div className='container inData level2Page'>
          <p className='t1'>متن خود را بنویسید</p>
          <div className='textBox'>
            <textarea value={text} onChange={(e:any)=>setText(e.target.value)} placeholder='متن' className=''></textarea>
          </div>
          <p className='t1'>آپلود مدارک</p>
          <div className='uploadPicture fileUploader'>
            {src && <img className='imgSelect' src={URL.createObjectURL(src)} alt='' />}
            <FileUploader handleChange={(e:any)=>setSrc(e)} defaultValue='' value='' name='fileImage' types={['JPG', 'PNG', 'JPEG']} />
            <span></span>
            <i>{docName}</i>
          </div>
          <div className='largeButton' onClick={sendImg}>
            ارسال درخواست
          </div>
        </div>
      </div>
    </div>
  );
};

export default Level2;
