import { zeroFormat } from "./numeral";

const faNums = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
const arNums = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
const enRegex = /[0-9]/g;
const faRegex = /[۰۱۲۳۴۵۶۷۸۹]/g;
const arRegex = /[٠١٢٣٤٥٦٧٨٩]/g;

const refreshPage = async () => {
  window.location.reload();
};

const numbersToEn = (str: string) => {
  return str.replace(faRegex, (char) => `${faNums.indexOf(char)}`).replace(arRegex, (char) => `${arNums.indexOf(char)}`);
};

const numbersToFa = (str: string) => {
  return str.replace(enRegex, (char) => `${faNums[Number(char)]}`).replace(arRegex, (char) => faNums[arNums.indexOf(char)]);
};

const dotted = (str: string, move: number = 10) => {
  if (str) {
    if (str?.length > 15) {
      return str?.slice(0, move) + ' ... ' + str?.slice(-4, str?.length) ;

    } else {
      return str
    }

  } else {
    return ""
  }
};

const removeComma = (value: any) => {
  value = value?.toString().replace(/,/g, '');
  return value
}

const crateQuerySearch = (listQuery: any) => {
  let txtQuery = "";

  if (listQuery && listQuery?.length > 0) {
    Array.from(listQuery)?.forEach((element: any) => {
      if (
        element?.value !== undefined &&
        element?.value?.toString()?.trim() !== "" &&
        element?.value !== null &&
        element?.value !== "NaN"
      ) {
        txtQuery = txtQuery + "&" + element?.key + "=" + element?.value
      }
    });
  }

  // return txtQuery?.replace("&", "?")
  return txtQuery
}

const countZeroDecimal = (value: any) => {
  if ((value % 1) != 0) {

    let splitted = value.toString().split(".")[1].split("");
    let i = 0;
    while (splitted.shift() == 0) {
      i += 1;
    }
    return i;
  }
  return 0;

};

const priceZeroDecimal = (newPrice: any, max = 4, isApi = true) => {
  if (countZeroDecimal(removeComma(zeroFormat(newPrice))?.toString()) > 3) {
    let splitted = removeComma(zeroFormat(Number(newPrice), max, isApi))?.toString().split(".")[1]
    return `0.0{${countZeroDecimal(removeComma(zeroFormat(Number(newPrice), max, isApi))?.toString())}}${Number(splitted)}`
  }

  return zeroFormat(Number(newPrice), max, isApi)

}

export { dotted, numbersToEn, numbersToFa, refreshPage, crateQuerySearch, removeComma, priceZeroDecimal, countZeroDecimal };
