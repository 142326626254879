import { useState } from 'react';
import { hideDrawer } from 'services/reducer/drawer';
import Code from './Code';
import Form from './Form';
import { useDispatch } from 'react-redux';

const Register = () => {
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState('');
  const [bodySend, setBodySend] = useState({});
  const dispatch = useDispatch();

  return (
    <div className='popup'>
      <div className='registerForm'>
        <div className='closePop' onClick={() => dispatch(hideDrawer())}></div>
        <div className='logo'></div>
        <div className='inForm'>
          {step === 1 ? (
            <Form mobile={mobile} setMobile={setMobile} setBodySend={setBodySend} setStep={setStep} />
          ) : (
            <Code mobile={mobile} setStep={setStep} bodySend={bodySend} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
