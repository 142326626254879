import useGlobal from 'hooks/useGlobal';
import moment from 'jalali-moment';
import { useDispatch } from 'react-redux';
import { hideDialog } from 'services/reducer/dialog';
import { useLocalStorage } from 'usehooks-ts';
import { typeColor } from 'utils/color';
import {  toFixed, zeroFormat } from 'utils/numeral';
import { sideTranslate, tradeTranslate } from 'utils/translate';


const ConfirmBuySellMarket = ({ data, send, close }: { data: any, send: any, close: any }) => {
  const global = useGlobal();
  const dispatch = useDispatch();
  const [confiemOrder, setConfiemOrder] = useLocalStorage('confiemOrder', "false");

  const dontShowDialog = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfiemOrder(event.target.checked + "")
  }


  return (
    <div className='popup'>
      <div className='closePop closePopWhite' onClick={() => dispatch(hideDialog())}></div>
      <div className='detailHistory'>
        <div className='headerBox'></div>
        <div className='voucherData'>
          <div className='listCashCode listCashCodeCustom'>
            <section>
              <div>
                <span>نوع سفارش</span>
                <span className={`color${typeColor(data.side)}`}>{sideTranslate(data.side)}</span>
              </div>
            </section>
            <section>
              <div>
                <span>نحوه سفارش</span>
                <span><p >{tradeTranslate(data.trade)}</p></span>
              </div>
            </section>
            <section>
              <div>
                <span>تاریخ</span>
                <span className='ltr'>
                  {moment().locale("fa").format('HH:mm  YYYY-MM-DD')}
                </span>
              </div>
            </section>
            <section>
              <div>
                <span>ارز</span>
                <span className='currencyDetail'>
                  {data.coin.name} ({data.coin.symbol.toUpperCase()})
                  <img crossOrigin='anonymous' src={global.coins[data.coin.symbol].icon} />
                </span>
              </div>
            </section>
            {data?.stop &&
              <section>
                <div>
                  <span>قیمت توقف</span>
                  <span className='ltr'>
                    {zeroFormat(data?.stop)} &nbsp; {data?.base?.toUpperCase()}
                  </span>
                </div>
              </section>
            }
            <section>
              <div>
                <span>مقدار</span>
                <span className='ltr'>
                  {zeroFormat(data?.amount)} &nbsp; {data.coin.symbol.toUpperCase()}
                </span>
              </div>
            </section>
            <section>
              <div>
                <span>قیمت</span>
                <span className='ltr'>
                  {zeroFormat(data?.price, data?.coin?.pair[data?.base]?.precision, false)} &nbsp; {data?.base?.toUpperCase()}
                </span>
              </div>
            </section>
            <section>
              <div>
                <span>جمع کل</span>
                <span className='ltr'>
                  {zeroFormat(toFixed(data?.total, data?.coin?.pair[data?.base]?.precision), data?.coin?.pair[data?.base]?.precision, false)}
                  &nbsp;{data?.base?.toUpperCase()}
                </span>
              </div>
            </section>
            <section>
              <div>
                <span>کارمزد</span>
                <span className='ltr'>
                  {zeroFormat(toFixed(data?.fee, data?.side == "buy"
                    ? data?.coin?.decimal
                    : data?.base=="tmn"?0: data?.coin?.pair[data?.base]?.precision)
                    , data?.side == "buy"
                      ? data?.coin?.decimal
                      : data?.base=="tmn"?0: data?.coin?.pair[data?.base]?.precision, false)}
                  &nbsp;  &nbsp;{data?.side == "buy" ? data?.coin?.symbol?.toUpperCase() : data?.base?.toUpperCase()}
                </span>
              </div>
            </section>
            <section>
              <div>
                <span>مقدار نهایی</span>
                <span className='ltr'>
                  {/* {data?.base=="tmn"? zeroFormat(toFixed(removeComma(data?.totalFee),0)) :zeroFormat(data?.totalFee)}  */}
                  {zeroFormat(toFixed(data?.totalFee, data?.side == "buy"
                    ? data?.coin?.decimal
                    : data?.base=="tmn"?0: data?.coin?.pair[data?.base]?.precision)
                    , data?.side == "buy"
                      ? data?.coin?.decimal
                      : data?.base=="tmn"?0: data?.coin?.pair[data?.base]?.precision, false)}
                  &nbsp;{data?.side == "buy"
                    ? data?.coin?.symbol?.toUpperCase()
                    : data?.base?.toUpperCase()}
                </span>
              </div>
            </section>
          </div>
        </div>
        <div className='borderHex'></div>
        <div className='checkBoxConfirmBuySellFast'>
          <input className='checkbox' onChange={dontShowDialog} type="checkbox" name="" id="checkId" />
          <label htmlFor="checkId">این تاییدیه را در معاملات بعد نشان نده</label>
        </div>
        <div className='btnsConfirmBuySellFast'>
          <button className="btnShowMore voucherBtn" onClick={send} > تایید  </button>
          <button className=" btnShowMore voucherBtn btnCancel" onClick={() => close()} > لغو  </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBuySellMarket;
