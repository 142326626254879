import config from 'config';
import { IDispatch } from 'interfaces/global';
import { ICard } from 'interfaces/identity';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getProfile } from 'services/reducer/user';
import gather from 'utils/gather';

const Document = ({ setStep }: ICard) => {
  const dispatch = useDispatch<IDispatch>();
  const [src, setSrc] = useState('');
  const [docName, setDocName] = useState("اینجا کلیک کنید یا فایل را بکشید");

  const saveAction = async (file: any) => {
    setDocName("اینجا کلیک کنید یا فایل را بکشید")
    dispatch(showLoading());

    const formData = new FormData();
    formData.append('type', 'document');
    formData.append('doc', file);

    const result = await gather(`${config.data.api}/v1/file`, true).upload(formData);
    if (result.code === 200) {
      setDocName(file?.name??"فایل با موفقیت ارسال شد")
      setSrc(result.data.link);
      await dispatch(getProfile());
      setTimeout(() => {
        dispatch(hideLoading());
      }, 3000);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
      dispatch(hideLoading());
      setDocName("خطا در ارسال دوباره تلاش کنید")
    }

  };

  return (
    <>
      <div className='emailLogo smsLogo smsLogoNew docNew'></div>
      <div className='rules rulesCenter'>
        لطفا یک کارت شناسایی خود را بارگذاری نمایید.
        <br />« کارت ملی یا گواهینامه یا شناسنامه »
      </div>
      <div className='uploadPicture fileUploader'>
        {src && <img src={src} alt='' />}
        <FileUploader handleChange={saveAction} defaultValue='' value='' name='fileImage' types={['JPG', 'PNG', 'JPEG']} />
        <span></span>
        <i>{docName}</i>
      </div>
      <div className='largeButton' onClick={() => setStep(3)}>
        تایید و ادامه
      </div>
    </>
  );
};

export default Document;
