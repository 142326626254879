import config from 'config';
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import Top from './Top';
import ItemLevelList from 'components/ItemLevelList';

const LevelList = () => {
  const dispatch = useDispatch();
  const { profile } = useUser();
  const [list, setList] = useState([]);
  const detailNeedLevel: any = {
    0: {
      "text1": "بدون نیاز",
      "text2": "بدون نیاز",
    },
    1: {
      "text1": "ثبت شماره تلفن همراه",
      "text2": "ثبت کارت بانکی",
    },
    2: {
      "text1": "تایید و تعهد قوانین اکسنوین",
      "text2": "ثبت و بارگزاری اطلاعات هویتی",
    },
    3: {
      "text1": "ارسال درخواست و مدارک",
      "text2": "منتطر بررسی کارشناس اکسنوین",
    },
    4: {
      "text1": "ارسال درخواست و مدارک",
      "text2": "منتطر بررسی کارشناس اکسنوین",
    }
  }

  const listRender = () => {
    const rows: JSX.Element[] = [];

    list
      .sort((a: any, b: any) => a.index - b.index)
      .forEach((e: any,i:any) => {
        rows.push(<ItemLevelList e={e} key={i} dispatch={dispatch} profile={profile} detailNeedLevel={detailNeedLevel} />
        );
      });

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/level`).get();
    if (result.code === 200) {
      setList(result.data);
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='popup'>
      <div className='registerForm colorForm registerFormBottom'>
        <Top title={'سطح کاربری'} />
        <div className='container'>{listRender()}</div>
      </div>
    </div>
  );
};

export default LevelList;
