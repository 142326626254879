import { IDeposit } from 'interfaces/detail';
import moment from 'jalali-moment';
import { useDispatch } from 'react-redux';
import { hideDialog } from 'services/reducer/dialog';
import { statusPayment } from 'utils/translate';
import Deposit from './Deposit';
import Order from './Order';
import Payment from './Payment';
import Voucher from './Voucher';
import Withdraw from './Withdraw';
import { hideDrawer } from 'services/reducer/drawer';

const Detail = ({ type, data }: IDeposit) => {
  const dispatch = useDispatch();

  return (
    <div className='popup'>
      <div className='closePop closePopWhite' onClick={() => { dispatch(hideDialog()); dispatch(hideDrawer()) }}></div>
      <div className='detailHistory'>
        <div className='headerBox'></div>
        {(type != "order" && type != "voucher")
          && <>
            <div className='stepData'>
              <div>
                <span></span>
                <h6>
                  ثبت درخواست
                  <i>
                    {moment(data.createdAt)
                      .locale('fa')
                      .format('YYYY-MM-DD , HH:mm:ss')}
                  </i>
                </h6>
              </div>
              <section></section>
              <div>
                <span></span>
                <h6>
                  پردازش سیستم
                  <i>
                    {moment(data.createdAt)
                      .locale('fa')
                      .format('YYYY-MM-DD , HH:mm:ss')}
                  </i>
                </h6>
              </div>
              <section></section>
              <div className={`step_${data.status}`}>
                <span></span>
                <h6>
                  {statusPayment(data.status)}
                  <i>
                    {moment(data.updatedAt)
                      .locale('fa')
                      .format('YYYY-MM-DD , HH:mm:ss')}
                  </i>
                </h6>
              </div>
            </div>
            <div className='borderHex'></div>
          </>
        }

        {/* <div className='noteEmail'>
          لطفا توجه داشته باشید پس از تکمیل شدن پرداخت یک ایمیل دریافت خواهید کرد. <span>ایمیل دریافت نکرده ام!</span>
        </div> */}

        {type === 'voucher' ? (
          <Voucher data={data} />
        ) : type === 'withdraw' ? (
          <Withdraw data={data} />
        ) : type === 'deposit' ? (
          <Deposit data={data} />
        ) : type === 'payment' ? (
          <Payment data={data} />
        ) : (
          <Order data={data} />
        )}
      </div>
    </div>
  );
};

export default Detail;
