import moment from 'jalali-moment';
import React, { useState } from 'react';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';
import { formatter } from 'utils/numeral';
import Identity from './Modules/Identity';
import Level1 from './Modules/Level1';
import Level2 from './Modules/Level2';
import _ from 'lodash';
import AddNationalAndBirthday from './AddNationalAndBirthday';

const ItemLevelList = ({ e, profile, dispatch, detailNeedLevel }: { e: any, profile: any, dispatch: any, detailNeedLevel: any }) => {

  const [show, setShow] = useState(profile.plane.level.index + 1 === e.index);


  const handleUpgrade = () => {
    dispatch(hideDrawer());
    if (profile?.plane?.level?.index > 0 && ((!profile?.verify?.identity || _.isNull(profile?.nationalCode))
      || _.isNull(profile?.birthDate) || profile?.nationalCode?.trim().length != 10
      || profile?.birthDate?.trim().length == 0)) {
      setTimeout(() => {
        dispatch(fullDrawer(<AddNationalAndBirthday />))
      }, 300);
      return
    }

    setTimeout(() => {
      dispatch(fullDrawer(e.index === 1 ? <Identity /> : e.index === 2 ? <Level1 /> : <Level2 />));
    }, 300);
  }

  return (
    <div className={`levelView ${show ? "" : "hide"}`}>
      <h3 style={{ background: e.color }} onClick={() => setShow(!show)}>
        <i>سطح {e.name}</i> {profile.plane.level.index >= e.index && <p style={{ color: e.color }}>تایید شده</p>}
      </h3>
      <div>
        <h6>اطلاعات مورد نیاز</h6>
        <section>
          <span>{detailNeedLevel[e.index <= 4 ? e.index : 4]?.text1}</span>
          <article></article>
          <span>{detailNeedLevel[e.index <= 4 ? e.index : 4]?.text2}</span>
        </section>
      </div>
      <div>
        <h6>امکانات</h6>
        <div>
          <span></span>
          <span>واریز تومانی روزانه</span>
          <span>نامحدود</span>
        </div>
        <div>
          <span></span>
          <span>برداشت تومانی روزانه</span>
          <span>{formatter(e.withdraw.fiat.daily)}</span>
        </div>
        <div>
          <span></span>
          <span>واریز دلاری روزانه</span>
          <span>نامحدود</span>
        </div>
        <div>
          <span></span>
          <span>برداشت دلاری روزانه</span>
          <span>{formatter(e.withdraw.coin.daily)}</span>
        </div>

        <div>
          <span></span>
          <span>واریز تومانی ماهانه</span>
          <span>نامحدود</span>
        </div>
        <div>
          <span></span>
          <span>برداشت تومانی ماهانه</span>
          <span>{formatter(e.withdraw.fiat.monthly)}</span>
        </div>
        <div>
          <span></span>
          <span>واریز دلاری ماهانه</span>
          <span>نامحدود</span>
        </div>
        <div>
          <span></span>
          <span>برداشت دلاری ماهانه</span>
          <span>{formatter(e.withdraw.coin.monthly)}</span>
        </div>
      </div>
      <div>
        <h6>کارمزد ها</h6>
        <div>
          <span></span>
          <span className='colorGreen'>کارمزد خرید تومانی</span>
          <span>{e.fee.tmn.buy} درصد</span>
        </div>
        <div>
          <span></span>
          <span className='colorRed'>کارمزد فروش تومانی</span>
          <span>{e.fee.tmn.sell} درصد</span>
        </div>
        <div>
          <span></span>
          <span className='colorGreen'>کارمزد خرید تتری</span>
          <span>{e.fee.usdt.buy} درصد</span>
        </div>
        <div>
          <span></span>
          <span className='colorRed'>کارمزد فروش تتری</span>
          <span>{e.fee.usdt.sell} درصد</span>
        </div>
      </div>
      {profile.plane.level.index + 1 === e.index &&
        <>
          {profile.plane.request === 'reject' && profile.plane.nextTime > (moment().unix() * 1000) ? (
            <h5 className='largeButton largeButtonLevel' style={{ background: 'red' }}>
              علت رد: {profile.plane.reason}
              <br />
              زمان بعدی درخواست:{' '}
              {moment(profile.plane.nextTime)
                .locale('fa')
                .format('YYYY-MM-DD ساعت HH:mm')}
            </h5>
          ) : profile.plane.request === 'pending' ? (
            <h5 className='largeButton largeButtonLevel' style={{ background: '#9f9f9f' }}>
              در انتظار بررسی
            </h5>
          ) : (
            <h5
              className='largeButton largeButtonLevel'
              style={{ background: e.color }}
              onClick={handleUpgrade}
            >
              ارتقا به سطح کاربری {e.index}
            </h5>
          )}
        </>
      }
    </div>
  );
}

export default ItemLevelList;
