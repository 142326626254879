import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCCAJ1potktK7Tj0u-gOcd9vjXHdSTz69E",
  authDomain: "exnovin-5e83a.firebaseapp.com",
  projectId: "exnovin-5e83a",
  storageBucket: "exnovin-5e83a.firebasestorage.app",
  messagingSenderId: "398562766834",
  appId: "1:398562766834:web:05ff4658d37e912d12456b",
  measurementId: "G-R70591MLM8"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export const messaging = getMessaging(app);