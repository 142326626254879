import { ITabs } from 'interfaces/helper';

const Tabs = ({ titles, selected, setSelected, isNew = false }: any): React.ReactElement => {
  const rows: React.ReactElement[] = [];

  Object.entries(titles).forEach(([key, value]: any) => {
    rows.push(
      <span key={key} className={`${value == "تومان" && "mytmn"} ${value == selected ? 'active' : ''} ${isNew && "posRelative"}`} onClick={() => setSelected(value)}>
        {value}
        {isNew &&
          (value === "کارت به کارت" || value === "واریز شناسه دار") &&
          <span className='textNewTab'>جدید</span>
        }

      </span>,
    );
  });

  return <>{rows}</>;
};

export default Tabs;
