import config from 'config';
import { IDispatch } from 'interfaces/global';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Uploader = ({ src, setSrc }: any) => {
  const dispatch = useDispatch<IDispatch>();

  const uploadFile = async (file: any) => {
    dispatch(showLoading());

    const formData = new FormData();
    formData.append('type', 'ticket');
    formData.append('doc', file);

    const result = await gather(`${config.data.api}/v1/file`, true).upload(formData);
    if (result.code === 200) {
      setSrc(result.data.link);
      setTimeout(() => {
        dispatch(hideLoading());
      }, 3000);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
      dispatch(hideLoading());
    }

  };

  return (
    <div className='uploadPicture fileUploader fileUploaderTicket'>
      {src && <img src={src} alt='' />}
      <FileUploader handleChange={uploadFile} defaultValue='' value='' name='fileImage' types={['JPG', 'PNG', 'JPEG']} />
      <span></span>
      <i>
        در صورت داشتن فایل
        <br />
        اینجا کلیک کنید یا فایل را بکشید
      </i>
    </div>
  );
};

export default Uploader;
