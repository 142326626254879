import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import _ from "lodash";
import VerifyCodeEmailPhone from "./VerifyCodeEmailPhone";
import Top from "../Top";
import PatchCode from "components/Helper/PatchCode";
import useTimer from "hooks/useTimer";
import gather from "utils/gather";
import config from "config";
import { hideLoading, showLoading } from "services/reducer/loading";
import { errorAlert } from "services/reducer/alert";
import { fullDrawer, hideDrawer } from "services/reducer/drawer";
import Profile from "components/Modules/Profile";
import EditPhone from "./EditPhone";
import VerificationInput from "react-verification-input";
import { numbersToEn } from "utils/tools";


const ConfirmPhoneCode = ({ value }: { value: any }) => {
  const [code, setCode] = useState<any>('');
  const dispatch = useDispatch<any>();
  const timer = useTimer();

  const openMenu = (content: JSX.Element) => {
    dispatch(hideDrawer());
    setTimeout(() => {
      dispatch(fullDrawer(content));
    }, 300);
  };

  const send = async (code1:any) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/mobile/confirm`,true).post({ code:numbersToEn(code1?.toString()) })

    if (result.code === 200) {
      openMenu(<EditPhone />)
    } else {
      setCode('');
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
    dispatch(hideLoading());
  }

  const reloadCode = async () => {

    const result = await gather(`${config.data.api}/v1/user/mobile`,true).post({ "mobile": value })

    if (result.message === 200) {
      timer.start();
      setCode('');
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

  }

  useEffect(() => {
    timer.start();
  }, []);

  return (

    <div className='popup'>
      <div className='registerForm colorForm registerFormBottom'>
        <Top title="کد تاییدارسالی به تلفن همراه" />
        <div className='logo'></div>
        <div className='container inData verifyCodeEmailPhone'>
          <h4 className='confirmTitle'>کد تایید به شماره {value} ارسال شد</h4>
          <div className='codeNumberBox'>
        <VerificationInput
          onComplete={(e) => send(e)}
          onChange={(e) => { Number(e) >= 0 && setCode(e) }}
          value={code}
          placeholder='-'
          inputProps={{ type: "tel" }}
          classNames={{
            container: "containerVerify",
            character: "characterVerify",
            characterFilled: "character--filled",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
        />
      </div>
          <div className='changeMobile'>
            <i>
              {!timer.isFinish ? (
                <>
                  {timer.minute ? `${timer.minute} دقیقه و ` : ''}
                  {timer.second} ثانیه
                </>
              ) : (
                <div className='active' onClick={reloadCode}>
                  ارسال مجدد کد
                </div>
              )}
            </i>
            <p onClick={() => openMenu(<Profile />)}>بازگشت</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmPhoneCode;